import styles from "./styles.module.scss";
import { ExpressDecodeOptionsModel, ExpressDecodeUseCasesModel } from "../../../interfaces/be.interfaces";

export interface ManageProductAddOrEditProductExpressTxConfigurationsActualProductProps {
  actualProduct: ExpressDecodeOptionsModel | null;
}

const ManageProductAddOrEditProductExpressTxConfigurationsActualProduct = ({
  actualProduct,
}: ManageProductAddOrEditProductExpressTxConfigurationsActualProductProps) => {
  const actual_product_configurations = actualProduct
    ? Object.keys(actualProduct).map((decode) => actualProduct[decode as keyof ExpressDecodeOptionsModel])
    : [];

  return (
    <div>
      <div className={styles.header}>Actual Product</div>
      <div className={styles.section}>
        <table className={styles.expressTxOptionsTable}>
          <caption className={styles.expressTxOptionsCaption}>Express Use Case Configurations - 6 Audio Channels</caption>
          <thead>
            <tr>
              <th>Decode</th>
              <th>Discrete</th>
              <th>Soundbar</th>
              <th>Hybrid</th>
              <th>Atmos</th>
            </tr>
          </thead>
          <tbody className={styles.expressTxOptionsTableBody}>
            {actual_product_configurations?.map(
              (config, index) =>
                config && (
                  <ManageProductAddOrEditProductExpressTxConfigurationsActualProductTableRow
                    key={`ManageProductExpressTxConfigurationsActualProductTableRow-${index}`}
                    expressDecodeUseCase={config}
                    index={index}
                  />
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export interface ManageProductAddOrEditProductExpressTxConfigurationsActualProductTableRowProps {
  expressDecodeUseCase: ExpressDecodeUseCasesModel;
  index: number;
}

export const ManageProductAddOrEditProductExpressTxConfigurationsActualProductTableRow = ({
  expressDecodeUseCase,
  index,
}: ManageProductAddOrEditProductExpressTxConfigurationsActualProductTableRowProps) => {
  return (
    <>
      <tr className={index % 2 === 0 ? styles.expressTxOptionsRowHighlighted : undefined}>
        <td className={styles.expressTxOptionsDataCell}>{expressDecodeUseCase.label}</td>
        <td className={styles.expressTxOptionsDataCell}>
          {
            <ManageProductAddOrEditProductExpressTxConfigurationsSpeakerList
              channelsSpeakers={expressDecodeUseCase.discrete || ["Discrete Unavailable"]}
            />
          }
        </td>
        <td className={styles.expressTxOptionsDataCell}>
          {
            <ManageProductAddOrEditProductExpressTxConfigurationsSpeakerList
              channelsSpeakers={expressDecodeUseCase.soundbar || ["Soundbar Unavailable"]}
            />
          }
        </td>
        <td className={styles.expressTxOptionsDataCell}>
          {
            <ManageProductAddOrEditProductExpressTxConfigurationsSpeakerList
              channelsSpeakers={expressDecodeUseCase.hybrid || ["Hybrid Unavailable"]}
            />
          }
        </td>
        <td className={styles.expressTxOptionsDataCell}>
          {
            <ManageProductAddOrEditProductExpressTxConfigurationsSpeakerList
              channelsSpeakers={expressDecodeUseCase.atmos || ["Atmos Unavailable"]}
            />
          }
        </td>
      </tr>
    </>
  );
};

export interface ManageProductAddOrEditProductExpressTxConfigurationsSpeakerListProps {
  channelsSpeakers: string[];
}

export const ManageProductAddOrEditProductExpressTxConfigurationsSpeakerList = ({
  channelsSpeakers,
}: ManageProductAddOrEditProductExpressTxConfigurationsSpeakerListProps) => {
  return (
    <>
      <ul>
        {channelsSpeakers.map((channelSpeaker, index) => (
          <li key={`ManageProductExpressTxConfigurationsSpeakerList-${index}`}>{channelSpeaker}</li>
        ))}
      </ul>
    </>
  );
};

export default ManageProductAddOrEditProductExpressTxConfigurationsActualProduct;
