import { useEffect, useState, useCallback } from "react";
import { typeGuard } from "../interfaces/fe.interfaces";

interface CustomerContext {
  // These fields are sent to the API
  vendorId: string | null;
  customerProductId: number | null;
  modelId: string | null;
  customerProductModelId: number | null;

  // These fields are for display purposes only
  productId: number | null;
  productName: string | null;
  customerName: string | null;
  modelDescription: string | null;
}

export const initialContext: CustomerContext = {
  vendorId: null,
  productId: null,
  modelId: null,
  productName: null,
  customerName: null,
  customerProductId: null,
  modelDescription: null,
  customerProductModelId: null,
};

/**
 * use this hook to get the customer context values and update it
 * @returns {CustomerContext} customer context
 * @returns {Function} updateContext function to update the context
 */
export const useCustomerContext = () => {
  const [customerContext, setCustomerContext] = useState(initialContext);
  const [fetching, setFetching] = useState(false);

  // Parse items from local storage to state on mount
  const parseLocalStorage = useCallback(() => {
    setFetching(true);
    const contextItem = localStorage.getItem("context");

    // If there is a context item in local storage, parse it and set it as the context
    // Otherwise, set the context to the initial context
    if (contextItem) {
      const context = JSON.parse(contextItem);

      // Checking if the context is of type CustomerContext
      if (
        typeGuard<CustomerContext>(context, [
          "customerName",
          "customerProductId",
          "customerProductModelId",
          "productId",
          "productName",
          "vendorId",
        ])
      ) {
        setCustomerContext(context);
      } else {
        setCustomerContext(initialContext);
      }
    } else {
      setCustomerContext(initialContext);
    }

    setFetching(false);
  }, []);

  useEffect(() => {
    parseLocalStorage();

    // Custom event listener to update the context when the customer context changes
    document.addEventListener("customerContextChanged", parseLocalStorage);

    return () => {
      document.removeEventListener("customerContextChanged", parseLocalStorage);
    };
  }, []);

  /**
   * Use this function to update values in customer context
   * @param {CustomerContext} newContext new context to update
   */
  const updateContext = useCallback((newContext: CustomerContext) => {
    localStorage.setItem("context", JSON.stringify(newContext));
    setCustomerContext(newContext);

    document.dispatchEvent(new CustomEvent("customerContextChanged"));
  }, []);

  return {
    context: customerContext,
    updateContext,
    fetching,
  };
};
