import { FormikProps } from "formik";
import styles from "./styles.module.scss";
import { CustomSequencePayload, SelectOption } from "../../interfaces/fe.interfaces";
import Select from "../Select";
import { GetCustomersResponse } from "../../interfaces/be.interfaces";
import { useFetch } from "../../hooks/useFetch";
import { getCustomers } from "../../api/config";
import { useState } from "react";
import Spinner from "../Spinner";
import { handleAlphaNumInputChange } from "../../utils/helpers";
import AccessGuard from "../Guards/AccessGuard";

interface AddOrUpdateSeqFormProps extends FormikProps<CustomSequencePayload> {
  onCancelClick: () => void;
  isEdit?: boolean;
}

const AddOrUpdateSeqForm = ({
  values,
  handleSubmit,
  setFieldValue,
  onCancelClick,
  touched,
  errors,
  handleBlur,
  isEdit = false,
}: AddOrUpdateSeqFormProps) => {
  const [customerList, setCustomerList] = useState<SelectOption<string | null>[]>([]);

  /**
   * Fetch list of customers to show in dropdown
   * only active customers are displayed in dropdown
   */
  const { loading } = useFetch<GetCustomersResponse>({
    runOnMount: true,
    url: getCustomers,
    onSuccess: (response) => {
      // Prepare customer data in option format to show in dropdown and filter inactive customers
      const customerOptions: SelectOption<string | null>[] = response.data?.data?.customers
        ?.filter((item) => item.is_enable)
        .map((customer) => ({
          label: customer.customer_name,
          value: customer.vendor_id,
        }));

      customerOptions.unshift({ label: "Select Customer", value: null });
      setCustomerList(customerOptions);
    },
  });

  if (loading) {
    return <Spinner />;
  }
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className={`${styles.inputContainer}`}>
        <input
          name="seq_name"
          type="text"
          value={values.seq_name}
          placeholder="Sequence title"
          onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
          onBlur={handleBlur}
        />
        {touched.seq_name && errors.seq_name && <p className={styles.error}>{errors.seq_name}</p>}
      </div>
      <AccessGuard>
        <div className={`${styles.inputContainer}`}>
          <Select
            selectProps={{
              value: values.vendor_id || "",
              onChange: (event) => setFieldValue("vendor_id", +event.target.value),
            }}
            options={customerList}
          />
        </div>
      </AccessGuard>
      <div className="w-100 d-flex justify-content-around">
        <button className={styles.dialogBtn} type="submit">
          {isEdit ? "Update" : "Add"}
        </button>
        <button className={styles.dialogBtn} onClick={onCancelClick}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddOrUpdateSeqForm;
