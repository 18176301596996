import { FormikProps } from "formik";
import { CustomCommandFormValues, CustomCommandResultType, SelectOption } from "../../interfaces/fe.interfaces";
import Select from "../Select";
import Spinner from "../Spinner";
import styles from "./styles.module.scss";
import Checkbox from "../Checkbox";
import { useFetch } from "../../hooks/useFetch";
import { GetCustomersResponse } from "../../interfaces/be.interfaces";
import { getCustomers } from "../../api/config";
import { ChangeEvent, useCallback, useState } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { handleAlphaNumInputChange, parseStringNumber } from "../../utils/helpers";
import { FLOATING_NUM_REGEX } from "../../utils/constants";
import AccessGuard from "../Guards/AccessGuard";

const ResultTypeOption = [
  { label: CustomCommandResultType.BOOLEAN, value: CustomCommandResultType.BOOLEAN },
  { label: CustomCommandResultType.STRING, value: CustomCommandResultType.STRING },
  { label: CustomCommandResultType.NUMBER, value: CustomCommandResultType.NUMBER },
];

const Form = ({
  values,
  handleChange,
  touched,
  setFieldTouched,
  errors,
  handleSubmit,
  handleReset,
  setFieldValue,
  isSubmitting,
  enableReinitialize,
}: FormikProps<CustomCommandFormValues>) => {
  const [customerList, setCustomerList] = useState<SelectOption<string | null>[]>([]);
  const [inputValue, setInputValue] = useState("");

  /**
   * Fetch list of customers to show in dropdown
   * only active customers are displayed in dropdown
   */
  const { loading } = useFetch<GetCustomersResponse>({
    runOnMount: true,
    url: getCustomers,
    onSuccess: (response) => {
      // Prepare customer data in option format to show in dropdown and filter inactive customers
      const customerOptions: SelectOption<string | null>[] = response.data?.data?.customers
        ?.filter((item) => item.is_enable)
        .map((customer) => ({
          label: customer.customer_name,
          value: customer.vendor_id,
        }));

      customerOptions.unshift({ label: "Select Customer", value: null });
      setCustomerList(customerOptions);
    },
  });

  /**
   * Handle change event for result type dropdown
   */
  const handleTypeChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue("result_type", event.target.value);

    // Reset passing values if result type is changed
    setFieldValue("passing_values", []);
    setFieldValue("min_value", null);
    setFieldValue("max_value", null);

    setFieldTouched("passing_values", false);
    setFieldTouched("min_value", false);
    setFieldTouched("max_value", false);
  }, []);

  const handleChangeTagInputValue = useCallback(
    (value: string) => {
      if (values.result_type === CustomCommandResultType.NUMBER && !FLOATING_NUM_REGEX.test(value)) {
        return;
      }

      setInputValue(value);
    },
    [values.result_type]
  );

  const disableMinMax = values.result_type !== null && values.result_type !== CustomCommandResultType.NUMBER;
  const handleMinMaxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (disableMinMax) return;

      const { name, value } = e.target;

      if (value === "") {
        setFieldValue(name, null);
        return;
      }

      if (FLOATING_NUM_REGEX.test(value)) {
        setFieldValue(name, value);
      }
    },
    [disableMinMax]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.dialog}>
        {isSubmitting || loading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.inputRow}>
              <div className={`${styles.inputContainer} d-flex`}>
                <input
                  name="cust_command_name"
                  type="text"
                  placeholder={"Enter Command Name"}
                  value={values.cust_command_name}
                  onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
                  onBlur={() => setFieldTouched("cust_command_name", true)}
                />
                {errors?.cust_command_name && touched.cust_command_name ? (
                  <p className={styles.error}>{errors?.cust_command_name}</p>
                ) : null}
              </div>
              <div className={`${styles.inputContainer} d-flex`}>
                <textarea
                  name="cust_command_description"
                  placeholder="Enter Description"
                  value={values.cust_command_description}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("cust_command_description", true)}
                  className={styles.command_description}
                />
                {errors?.cust_command_description && touched.cust_command_description ? (
                  <p className={styles.error}>{errors?.cust_command_description}</p>
                ) : null}
              </div>
            </div>
            <div className={styles.inputRow}>
              <AccessGuard>
                <div className={`${styles.inputContainer} d-flex`}>
                  <Select
                    selectProps={{
                      name: "vendor_id",
                      value: values.vendor_id || "",
                      onChange: (event) => setFieldValue("vendor_id", +event.target.value),
                    }}
                    options={customerList}
                  />
                  {errors?.vendor_id && touched.vendor_id ? <p className={styles.error}>{errors?.vendor_id}</p> : null}
                </div>
              </AccessGuard>
              <div className={`${styles.inputContainer} d-flex`}>
                <Checkbox
                  label="Move on with failure?"
                  name="move_on"
                  checked={values.move_on}
                  onChange={() => setFieldValue("move_on", !values.move_on)}
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={`${styles.inputContainer} d-flex`}>
                <Select
                  selectProps={{
                    name: "result_type",
                    value: values.result_type || "",
                    placeholder: "Select Result Type",
                    onChange: handleTypeChange,
                  }}
                  options={ResultTypeOption}
                />
                {errors?.result_type && touched.result_type ? <p className={styles.error}>{errors?.result_type}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex`}>
                <div className={styles.minMaxContainer}>
                  <input
                    name="min_value"
                    type="text"
                    placeholder={"Min"}
                    value={values.min_value ?? ""}
                    onChange={handleMinMaxChange}
                    onBlur={() => setFieldTouched("min_value", true)}
                    disabled={disableMinMax}
                  />
                  <input
                    name="max_value"
                    type="text"
                    placeholder={"Max"}
                    value={values.max_value ?? ""}
                    onChange={handleMinMaxChange}
                    onBlur={() => setFieldTouched("max_value", true)}
                    disabled={disableMinMax}
                  />
                </div>
                {errors?.min_value && touched.min_value ? (
                  <p className={styles.error}>{errors?.min_value}</p>
                ) : errors?.max_value && touched.max_value ? (
                  <p className={styles.error}>{errors?.max_value}</p>
                ) : null}
              </div>
            </div>
            <div className={styles.inputRow}>
              {
                // Show passing values only if result type is not boolean
                values.result_type && values.result_type === CustomCommandResultType.STRING && (
                  <div className={`${styles.inputContainer} d-flex`}>
                    <TagsInput
                      value={values.passing_values}
                      onChange={(_, tags) => {
                        if (values.passing_values.includes(tags[0])) {
                          const filter = values.passing_values.filter((item) => item !== tags[0]);
                          setFieldValue("passing_values", filter);
                        } else {
                          const valuesToAdd = values.result_type === CustomCommandResultType.NUMBER ? tags.map(parseStringNumber) : tags;
                          setFieldValue("passing_values", [...valuesToAdd, ...values.passing_values]);
                          setInputValue("");
                        }
                      }}
                      inputValue={inputValue}
                      onChangeInput={handleChangeTagInputValue}
                      onlyUnique
                      inputProps={{
                        placeholder: "Enter Passing Values",
                      }}
                      renderLayout={(tagComponents, inputComponent) => (
                        <>
                          {inputComponent}
                          <div className={styles.tagsContainer}>{tagComponents}</div>
                        </>
                      )}
                    />
                    {errors?.passing_values && touched.passing_values ? <p className={styles.error}>{errors?.passing_values}</p> : null}
                  </div>
                )
              }
            </div>
            <div className="w-100 d-flex justify-content-around">
              <button className={styles.dialogBtn} type="submit" disabled={isSubmitting}>
                {enableReinitialize ? "Update" : "Add"}
              </button>
              <button type="button" className={styles.dialogBtn} onClick={handleReset}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Form;
