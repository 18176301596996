import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { GetProductListResponse } from "../../interfaces/be.interfaces";
import { getProductsList } from "../../api/config";
import Spinner from "../../components/Spinner";
import { initialContext, useCustomerContext } from "../../hooks/useCustomerContext";
import AccessGuard from "../../components/Guards/AccessGuard";
import { CUSTOMER_MODULES, PERMISSIONS } from "../../utils/constants";

const Home = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>();
  const { context, updateContext } = useCustomerContext();

  const { data, loading: isListLoading } = useFetch<GetProductListResponse>({
    url: getProductsList,
    runOnMount: true,
  });

  const productList = useMemo(() => {
    const list = data?.data?.list
      ?.find((item) => item.vendor_id === selectedTab)
      ?.customer_products.filter((item) => item.is_enable && item.product_name.toLowerCase().includes(searchText.toLowerCase()));

    return list ?? [];
  }, [data, searchText, selectedTab]);

  const customerName = useMemo(() => {
    return data?.data?.list?.find((item) => item.vendor_id === selectedTab)?.customer_name;
  }, [data, selectedTab]);

  const handleModelClick = useCallback((newContext = initialContext) => {
    updateContext(newContext);
  }, []);

  useEffect(() => {
    setSelectedTab(context.vendorId ?? data?.data?.list?.[0]?.vendor_id);
  }, [context, data]);

  if (isListLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className={styles.tabsContainer}>
        <ul className={styles.tabsList}>
          {data?.data?.list?.map((item) => (
            <li
              className={`${item.vendor_id === selectedTab ? styles.active : ""}`}
              key={item.vendor_id}
              onClick={() => {
                setSelectedTab(item.vendor_id);
              }}>
              {item.customer_name}
            </li>
          ))}
        </ul>
        <div className={styles.optionsContainer}>
          <AccessGuard permission={PERMISSIONS.CUSTOMER_LIST} modules={CUSTOMER_MODULES.MANAGE_CUSTOMER}>
            <Link to="/manage-customer">Manage Customers</Link>
          </AccessGuard>
          <input type="text" value={searchText} placeholder="Search Product" onChange={(e) => setSearchText(e.target.value)} />
        </div>
      </div>
      <div className={styles.dataContainer}>
        {productList.length ? (
          productList?.map((item) => {
            const enabledModels = item.customer_product_models?.filter((model) => model.is_enable);

            return (
              <div className={styles.productItem} key={item.customer_product_id}>
                <p>{item.product_name}</p>
                {enabledModels?.length ? (
                  enabledModels?.map((model) => (
                    <div
                      className={`${styles.modelItem} ${
                        model.customer_product_model_id === context.customerProductModelId ? styles.active : ""
                      }`}
                      key={model.model_id}
                      onClick={() => {
                        if (
                          model.customer_product_model_id === context.customerProductModelId &&
                          model.customer_product_id === context.customerProductId
                        ) {
                          handleModelClick();
                        } else {
                          handleModelClick({
                            vendorId: selectedTab ?? "",
                            productId: item.product_id,
                            modelId: model.model_id,
                            customerProductModelId: model.customer_product_model_id,
                            customerName: customerName ?? "",
                            productName: item.product_name,
                            customerProductId: item.customer_product_id,
                            modelDescription: model.model_description,
                          });
                          navigate("/DEVICE_CONFIGURATION");
                        }
                      }}>
                      <p className={styles.modelTitle}>{model.model_id}</p>
                      <p className={styles.modelDesc}>{model.model_description}</p>
                    </div>
                  ))
                ) : (
                  <div className={styles.empty}>
                    <span>No Models Found</span>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className={styles.empty}>
            <span>No Products Found</span>
          </div>
        )}
        <AccessGuard permission={PERMISSIONS.PRODUCT_LIST} modules={CUSTOMER_MODULES.MANAGE_PRODUCT}>
          <Link to="/manage-product">Manage Products</Link>
        </AccessGuard>
      </div>
    </div>
  );
};

export default Home;
