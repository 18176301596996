import { ArrayHelpers, FieldArray, FormikProps, getIn } from "formik";
import isEqual from "lodash/isEqual";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Icons } from "../../../../assets/images";
import {
  getAllRegionsList,
  getModelConfigurationList,
  getModelSizeList,
  getModelUseCaseList,
  getWisaFirmwareVersions,
} from "../../../api/config";
import { emptyModelObj } from "../../../containers/ManageProduct/ManageProductCreate";
import { useFetch } from "../../../hooks/useFetch";
import {
  GetModelConfigListResponse,
  GetRegionsList,
  GetWisaFirmwareVersionsResponse,
  Model,
  ModelTypes,
  ModelUseCase,
  ProductTypes,
} from "../../../interfaces/be.interfaces";
import { ModelConfigList, ProductFormInitValues, SelectOption } from "../../../interfaces/fe.interfaces";
import {
  PERMISSIONS,
  PCM_FORMAT_ENTERPRISE,
  BITS_PER_FRAME_SAMPLE_ENTERPRISE,
  SAMPLE_RATE_ENTERPRISE,
  USE_CASES,
} from "../../../utils/constants";
import { destructModelConfigList, handleAlphaNumInputChange, handleNumberInputChange } from "../../../utils/helpers";
import Checkbox from "../../../components/Checkbox";
import DeleteConfirmation from "../../../components/ConfirmationDialog";
import AccessGuard from "../../../components/Guards/AccessGuard";
import Select from "../../../components/Select";
import styles from "./styles.module.scss";
import ManageProductAddOrEditProductExpressTxConfigurations from "./ManageProductAddOrEditProductExpressTxConfigurations";

const TOOLTIP_MESSAGE = "MCU and AMP can't be selected together";

export interface ManageProductAddOrEditProductFormProps extends FormikProps<ProductFormInitValues> {
  pageTitle: string;
  customerName: string | undefined;
}

interface SelectUseCase {
  label: string;
  value: number;
  positions_required: boolean;
}

/**
 * Creates UI for add or edit product form
 * takes in formik props and page title as props
 *
 * This can be reused for both add and edit product form with initial values changing
 */
const ManageProductAddOrEditProductForm = (props: ManageProductAddOrEditProductFormProps) => {
  const { handleSubmit, pageTitle, handleChange, values, handleBlur, customerName, errors, touched, setFieldValue, setFieldTouched } =
    props;

  const [deleteModelId, setDeleteModelId] = useState<number | null>(null);
  const [modelConfigurations, setModelConfigurations] = useState<ModelConfigList>({
    amp_configs: [],
    chime_configurations: [],
    mcu_firmware_versions: [],
  });

  // Fetch size list from backend to populate in dropdown
  const [sizeList, setSizeList] = useState<SelectOption<string>[]>([]);
  useFetch<{ data: { size_arr: string[] } }>({
    url: getModelSizeList,
    runOnMount: true,
    onSuccess: (res) => {
      setSizeList(res.data.data.size_arr.map((size) => ({ label: size, value: size })));
    },
  });

  // Fetch use case list from backend to populate in dropdown
  const { data } = useFetch<ModelUseCase>({
    url: getModelUseCaseList,
    runOnMount: true,
  });

  const { data: regionList } = useFetch<GetRegionsList>({
    url: getAllRegionsList,
    runOnMount: true,
  });

  const { data: wisaFirmwareVersionResponse } = useFetch<GetWisaFirmwareVersionsResponse>({
    url: getWisaFirmwareVersions,
    runOnMount: true,
  });

  /**
   * Fetch list of amp configuration and MCU firmware versions to show in dropdown
   * only active amp configuration and MCU firmware versions are displayed in dropdown
   */
  const { data: modelConfigList } = useFetch<GetModelConfigListResponse>({
    runOnMount: true,
    url: getModelConfigurationList,
    onSuccess: (response) => {
      const lists = destructModelConfigList(response.data?.data, values.vendor_id);

      setModelConfigurations(lists);
    },
  });

  /**
   * Handles change event for all checkbox input fields
   */
  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.name, e.target.checked);

      // If user selects update mcu then deselect config amp and vice versa
      if (e.target.name === "will_update_mcu") {
        setFieldValue("will_config_amp", false);
      } else if (e.target.name === "will_config_amp") {
        setFieldValue("will_update_mcu", false);
      }

      // When user selects/deselects energy star then clear time out duration field
      if (e.target.name === "energy_star") {
        setFieldValue("time_out_duration", null);
        setFieldTouched("time_out_duration", false);
      }
    },
    [setFieldValue]
  );

  /**
   * Handles region checkbox's change event
   */
  const handleRegionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const selectedRegion = e.target.name;

      // Removing from array if already present otherwise push to it
      if (values.region_code_arr.includes(selectedRegion)) {
        setFieldValue(
          "region_code_arr",
          values.region_code_arr.filter((region) => region !== selectedRegion)
        );
      } else {
        setFieldValue("region_code_arr", [...values.region_code_arr, selectedRegion]);
      }
    },
    [values]
  );

  /**
   * Change event for product type
   * Handles clearing of use case and model fields
   */
  const handleChangeProductType = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setFieldValue("product_type", e.target.value);

      // Reset use case of all the models and product itself on change of product type
      setFieldValue("use_case_id", "");
      setFieldValue("version_id", "");
      const newModels = values.models_arr.map((model) => {
        return { ...model, use_case_id: "" };
      });
      setFieldValue("models_arr", newModels);
    },
    [values, setFieldValue]
  );

  // Filters use cases based on product type and tx/rx
  const filteredUseCases = useMemo(() => {
    const txUseCases: SelectUseCase[] = [];
    const rxUseCases: SelectUseCase[] = [];

    data?.data?.forEach((item) => {
      const obj = { label: item.use_case, value: item.use_case_id, positions_required: item.positions_required };

      // push to array only of use case type is same as selected product type.
      if (item.product_type === values.product_type) {
        // filter based on tx/rx
        if (item.tx_rx === ModelTypes.TX) {
          txUseCases.push(obj);
        } else {
          rxUseCases.push(obj);
        }
      }
    });

    return {
      txUseCases,
      rxUseCases,
    };
  }, [data, values.product_type]);

  /**
   * Change event for model type
   * @param {string} value - Updated model type value
   * @param {number} modelIndex - Index of model array which needs to change
   */
  const onChangeModelType = useCallback(
    (value: string, modelIndex: number) => {
      setFieldValue(`models_arr[${modelIndex}].tx_rx`, value);
      setFieldValue(`models_arr[${modelIndex}].use_case_id`, "");
      if (value === ModelTypes.TX) {
        setFieldValue(`models_arr[${modelIndex}].use_case_id`, values.use_case_id);
        setFieldValue(`models_arr[${modelIndex}].crossover`, 0);
        setFieldValue(`models_arr[${modelIndex}].size`, "");
      }
    },
    [values]
  );

  const filteredFirmwareVersions = useMemo(() => {
    const versions =
      wisaFirmwareVersionResponse?.data?.list
        ?.filter((version) => version.wisa_product === values.product_type)
        ?.map((item) => {
          return { label: item.version, value: item.version_id };
        }) || [];

    if (values.version_id && versions.length) {
      versions.unshift({ value: 0, label: "Select firmware version" });
    }
    return versions;
  }, [wisaFirmwareVersionResponse, values.product_type, values.version_id]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <div className="d-flex align-item-center justify-content-between">
          <h2>{pageTitle}</h2>
          <button type="submit">Submit</button>
        </div>
        <div className={styles.addProductContainer}>
          <div className={styles.inputItem}>
            <label>Product ID</label>
            <input
              type="text"
              placeholder="Enter product ID"
              value={values.product_id}
              name="product_id"
              onBlur={handleBlur}
              onChange={(e) => handleNumberInputChange(e, setFieldValue)}
            />
            {touched?.product_id && errors?.product_id ? <span className={styles.error}>{errors?.product_id}</span> : null}
          </div>
          <div className={styles.inputItem}>
            <label>Product name</label>
            <input
              type="text"
              placeholder="Enter product name"
              value={values.product_name}
              name="product_name"
              onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
              onBlur={handleBlur}
            />
            {touched?.product_name && errors?.product_name ? <span className={styles.error}>{errors?.product_name}</span> : null}
          </div>
          <div className={styles.inputItem}>
            <label>Customer name</label>
            <input type="text" placeholder="Customer name" disabled value={customerName} name="vendor_id" />
            {touched?.vendor_id && errors?.vendor_id ? <span className={styles.error}>{errors?.vendor_id}</span> : null}
          </div>
          <div className={styles.inputItem}>
            <label>Product description</label>
            <textarea
              rows={1}
              placeholder="Enter Description"
              value={values.product_description}
              name="product_description"
              onChange={handleChange}
              onBlur={handleBlur}></textarea>
            {touched?.product_description && errors?.product_description ? (
              <span className={styles.error}>{errors?.product_description}</span>
            ) : null}
          </div>
          <div className={styles.inputItem}>
            <label>Model type?</label>
            <Select
              selectProps={{
                name: "product_type",
                value: values.product_type || "",
                placeholder: "Select product type",
                onChange: handleChangeProductType,
              }}
              options={[
                { label: "WiSA-DS", value: ProductTypes.WiSA_DS },
                { label: "WiSA-E", value: ProductTypes.WiSA_E },
              ]}
            />
            {touched?.product_type && errors?.product_type ? <span className={styles.error}>{errors.product_type}</span> : null}
          </div>
          <div className={styles.inputItem}>
            <label>Use Case</label>
            <Select
              selectProps={{
                name: "use_case_id",
                value: values.use_case_id || "",
                placeholder: "Select use case",
                onChange: (event) => {
                  setFieldValue("use_case_id", +event.target.value);
                  values.models_arr.forEach((item, index) => {
                    if (item.tx_rx === ModelTypes.TX) {
                      setFieldValue(`models_arr[${index}].use_case_id`, +event.target.value);
                      setFieldValue(
                        `models_arr[${index}].use_case`,
                        filteredUseCases.txUseCases.find((item) => item.value === +event.target.value)?.label
                      );
                    }
                  });
                },
              }}
              options={filteredUseCases.txUseCases}
            />
            {touched?.use_case_id && errors?.use_case_id ? <span className={styles.error}>{errors.use_case_id}</span> : null}
          </div>
          <div className={styles.inputItem}>
            <label>Firmware Version</label>
            <Select
              selectProps={{
                name: "firmware_version",
                value: values.version_id || "",
                placeholder: filteredFirmwareVersions.length ? "Select firmware version" : "No firmware available",
                onChange: (event) => {
                  setFieldValue("version_id", +event.target.value);
                },
              }}
              options={filteredFirmwareVersions}
            />
          </div>
          {values.product_type === ProductTypes.WiSA_E && (
            <>
              <div className={styles.inputItem}>
                <label>PCM Format {values.pcm_format}</label>
                <Select
                  selectProps={{
                    name: "pcm_format",
                    value: values.pcm_format || "",
                    placeholder: "Select pcm format",
                    onChange: (event) => {
                      setFieldValue("pcm_format", +event.target.value);
                    },
                  }}
                  options={[
                    { label: "Unknown", value: PCM_FORMAT_ENTERPRISE.PCM_FORMAT_UNKNOWN },
                    { label: "S16 LE", value: PCM_FORMAT_ENTERPRISE.PCM_FORMAT_S16_LE },
                    { label: "S32 LE", value: PCM_FORMAT_ENTERPRISE.PCM_FORMAT_S32_LE },
                    { label: "S24 LE", value: PCM_FORMAT_ENTERPRISE.PCM_FORMAT_S24_LE },
                    { label: "S24 H LE", value: PCM_FORMAT_ENTERPRISE.PCM_FORMAT_S24_H_LE },
                  ]}
                />
              </div>
              <div className={styles.inputItem}>
                <label>Bits Per Frame Sample</label>
                <Select
                  selectProps={{
                    name: "bits_per_frame_sample",
                    value: values.bits_per_frame_sample || "",
                    placeholder: "Select bits per frame sample",
                    onChange: (event) => {
                      setFieldValue("bits_per_frame_sample", +event.target.value);
                    },
                  }}
                  options={[
                    { label: "FS32", value: BITS_PER_FRAME_SAMPLE_ENTERPRISE.FS32 },
                    { label: "FS64", value: BITS_PER_FRAME_SAMPLE_ENTERPRISE.FS64 },
                  ]}
                />
              </div>
              <div className={styles.inputItem}>
                <label>Sample Rate</label>
                <Select
                  selectProps={{
                    name: "sample_rate",
                    value: values.sample_rate || "",
                    placeholder: "Select sample rate",
                    onChange: (event) => {
                      setFieldValue("sample_rate", +event.target.value);
                    },
                  }}
                  options={[
                    { label: "44.1K", value: SAMPLE_RATE_ENTERPRISE.SAMPLE_RATE_44_1K },
                    { label: "48K", value: SAMPLE_RATE_ENTERPRISE.SAMPLE_RATE_48K },
                    { label: "88.2K", value: SAMPLE_RATE_ENTERPRISE.SAMPLE_RATE_88_2K },
                    { label: "96K", value: SAMPLE_RATE_ENTERPRISE.SAMPLE_RATE_96K },
                    { label: "176.4K", value: SAMPLE_RATE_ENTERPRISE.SAMPLE_RATE_176_4K },
                    { label: "192K", value: SAMPLE_RATE_ENTERPRISE.SAMPLE_RATE_192K },
                    { label: "Auto", value: SAMPLE_RATE_ENTERPRISE.SAMPLE_RATE_AUTO },
                  ]}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.configurationContainer}>
          <div>
            <div className={styles.header}>Product Configurations</div>
            <div className={styles.configs}>
              <div>
                <Checkbox
                  label="Map Association Mode"
                  name="map_assc_mode"
                  checked={values.map_assc_mode}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label="TX Clock Follower"
                  name="tx_clock_follower"
                  checked={values.tx_clock_follower}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label="Sub Content Provided"
                  name="sub_com_provided"
                  checked={values.sub_com_provided}
                  onChange={handleCheckboxChange}
                />
                <Checkbox label="Will Certify?" name="will_certify" checked={values.will_certify} onChange={handleCheckboxChange} />
              </div>
              <div>
                <p className={styles.subHeader}>Regions</p>
                <div className={styles.regionContainer}>
                  {regionList &&
                    regionList?.data?.regions?.map((region) => (
                      <Checkbox
                        label={region.region_name}
                        key={region.region_code}
                        checked={values.region_code_arr.includes(region.region_code)}
                        name={region.region_code}
                        onChange={handleRegionChange}
                      />
                    ))}
                  <div className={styles.otherRegionCheckboxContainer}>
                    <Checkbox
                      label={"Other"}
                      checked={values?.other}
                      name="other"
                      onChange={() => {
                        setFieldValue("other", !values.other);
                        if (values.other) setFieldValue("other_region", null);
                      }}
                    />
                    {values.other ? (
                      <div className={styles.otherRegionInputContainer}>
                        <input
                          type="text"
                          name="other_region"
                          placeholder="Enter Region"
                          value={values.other_region ?? ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched?.other_region && errors?.other_region ? (
                          <span className={styles.error}>{errors?.other_region}</span>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.header}>WiSA Server Features</div>
            <div className={styles.configs}>
              <div>
                <Checkbox label="WiSA Server" name="wisa_server" checked={values.wisa_server} onChange={handleCheckboxChange} />
                <Checkbox label="Notifications" name="notifications" checked={values.notifications} onChange={handleCheckboxChange} />
                <div className={styles.tooltipContainer}>
                  <Checkbox
                    label="Will Configure AMP?"
                    name="will_config_amp"
                    checked={values.will_config_amp}
                    onChange={handleCheckboxChange}
                    disabled={values.will_update_mcu}
                  />
                  <span id="amp_tooltip">{values.will_update_mcu ? <>&nbsp;&#9432;</> : null}</span>
                </div>
                <div className={styles.tooltipContainer}>
                  <Checkbox
                    label="Will Configure Chime?"
                    name="will_config_chime"
                    checked={values.will_config_chime}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className={styles.energyStarContainer}>
                  <Checkbox label="Energy Star" name="energy_star" checked={values.energy_star} onChange={handleCheckboxChange} />
                  {values.energy_star ? (
                    <div className={styles.timeoutInputContainer}>
                      <div className={styles.timeoutInput}>
                        <input
                          type="text"
                          name="time_out_duration"
                          placeholder="Timeout duration"
                          value={values.time_out_duration ?? ""}
                          onChange={(e) => handleNumberInputChange(e, setFieldValue)}
                          onBlur={handleBlur}
                        />
                        <span>(s)</span>
                      </div>
                      {touched?.time_out_duration && errors?.time_out_duration ? (
                        <span className={styles.error}>{errors?.time_out_duration}</span>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <p className={styles.subHeader}>Requires Notification</p>
                <div className={styles.borderBox}>
                  <Checkbox label="RX Health" name="rx_health" checked={values.rx_health} onChange={handleCheckboxChange} />
                  <Checkbox
                    label="Volume Management in App"
                    name="volume_manage_in_apps"
                    checked={values.volume_manage_in_apps}
                    onChange={handleCheckboxChange}
                  />
                  <Checkbox
                    label="Software Buttons"
                    name="software_buttons"
                    checked={values.software_buttons}
                    onChange={handleCheckboxChange}
                  />
                  <div className={styles.tooltipContainer}>
                    <Checkbox
                      label="Will Update MCU?"
                      name="will_update_mcu"
                      checked={values.will_update_mcu}
                      onChange={handleCheckboxChange}
                      disabled={values.will_config_amp}
                    />
                    <span id="mcu_tooltip">{values.will_config_amp ? <>&nbsp;&#9432;</> : null}</span>
                  </div>
                  <Checkbox
                    label="Small Datagrams"
                    name="small_datagrams"
                    checked={values.small_datagrams}
                    onChange={handleCheckboxChange}
                  />
                  <Checkbox
                    label="Large Datagrams"
                    name="large_datagrams"
                    checked={values.large_datagrams}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*
          This is the section for adding models to product
          Models can be added or removed dynamically
        */}
        <FieldArray name="models_arr">
          {({ push, remove }: ArrayHelpers<Model[]>) => (
            <>
              {values?.models_arr?.map((model, index) => {
                // touched and errors are not available for nested fields
                // so we need to get them from formik context
                const modelTouched = getIn(touched, `models_arr[${index}]`);
                const modelErrors = getIn(errors, `models_arr[${index}]`);

                return (
                  <div className={styles.addModelContainer} key={index}>
                    <div className={styles.inputItem}>
                      <label>Model ID</label>
                      <input
                        type="text"
                        placeholder="Enter model ID"
                        value={model.model_id}
                        name={`models_arr[${index}].model_id`}
                        onBlur={handleBlur}
                        onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
                      />
                      {modelTouched?.model_id && modelErrors?.model_id ? (
                        <span className={styles.error}>{modelErrors?.model_id}</span>
                      ) : null}
                    </div>
                    <div className={styles.inputItem}>
                      <label>Model Description</label>
                      <textarea
                        rows={1}
                        placeholder="Enter Description"
                        value={model.model_description}
                        name={`models_arr[${index}].model_description`}
                        onChange={handleChange}
                        onBlur={handleBlur}></textarea>
                      {modelTouched?.model_description && modelErrors?.model_description ? (
                        <span className={styles.error}>{modelErrors?.model_description}</span>
                      ) : null}
                    </div>
                    <div className={styles.inputItem}>
                      <label>Model type?</label>
                      <Select
                        selectProps={{
                          name: "tx_rx",
                          value: model.tx_rx || "",
                          placeholder: "Select model type",
                          onChange: (event) => onChangeModelType(event.target.value, index),
                        }}
                        options={[
                          { label: "Transmitter", value: ModelTypes.TX },
                          { label: "Receiver", value: ModelTypes.RX },
                        ]}
                      />
                      {modelTouched?.tx_rx && modelErrors?.tx_rx ? <span className={styles.error}>{modelErrors?.tx_rx}</span> : null}
                    </div>
                    <div className={styles.inputItem}>
                      <label>Cross Over(Hz)</label>
                      <input
                        type="text"
                        placeholder="Enter cross over point"
                        value={model.crossover}
                        name={`models_arr[${index}].crossover`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={model.tx_rx === ModelTypes.TX}
                      />
                      {modelTouched?.crossover && modelErrors?.crossover ? (
                        <span className={styles.error}>{modelErrors?.crossover}</span>
                      ) : null}
                    </div>
                    <div className={styles.inputItem}>
                      <label>Use case</label>
                      <Select
                        selectProps={{
                          name: "use_case_id",
                          value: model.use_case_id || "",
                          placeholder: "Select use case",
                          disabled: model.tx_rx === ModelTypes.TX,
                          onChange: (event) => {
                            setFieldValue(`models_arr[${index}].use_case_id`, +event.target.value);
                            // Choosing current model use cases
                            const useCases = model.tx_rx === ModelTypes.TX ? filteredUseCases.txUseCases : filteredUseCases.rxUseCases;

                            // Selecting "positions_required" for chime configuration
                            setFieldValue(
                              `models_arr[${index}].positions_required`,
                              useCases.find((item) => item.value === +event.target.value)?.positions_required || false
                            );
                          },
                        }}
                        options={model.tx_rx === ModelTypes.TX ? filteredUseCases.txUseCases : filteredUseCases.rxUseCases}
                      />
                      {modelTouched?.use_case_id && modelErrors?.use_case_id ? (
                        <span className={styles.error}>{modelErrors?.use_case_id}</span>
                      ) : null}
                    </div>
                    <div className={styles.inputItem}>
                      <label>Size</label>
                      <Select
                        selectProps={{
                          name: "size",
                          value: model.size || "",
                          placeholder: "Select Model Size",
                          disabled: model.tx_rx === ModelTypes.TX,
                          onChange: (event) => setFieldValue(`models_arr[${index}].size`, event.target.value),
                        }}
                        options={sizeList}
                      />
                      {modelTouched?.size && modelErrors?.size ? <span className={styles.error}>{modelErrors?.size}</span> : null}
                    </div>
                    {values.will_config_chime ? (
                      <>
                        <div className={styles.inputItem}>
                          <label>Left chime config version</label>
                          <Select
                            selectProps={{
                              name: "left_speaker_chime_config_id",
                              value: model.left_speaker_chime_config_id,
                              onChange: (event) => setFieldValue(`models_arr[${index}].left_speaker_chime_config_id`, event.target.value),
                            }}
                            options={modelConfigurations.chime_configurations}
                          />
                          {modelTouched?.left_speaker_chime_config_id && modelErrors?.left_speaker_chime_config_id && (
                            <span className={styles.error}>{modelErrors?.left_speaker_chime_config_id}</span>
                          )}
                        </div>
                        {model.positions_required && (
                          <div className={styles.inputItem}>
                            <label>Right chime config version</label>
                            <Select
                              selectProps={{
                                name: "right_speaker_chime_config_id",
                                value: model.right_speaker_chime_config_id,
                                onChange: (event) => {
                                  setFieldValue(`models_arr[${index}].right_speaker_chime_config_id`, event.target.value);
                                },
                              }}
                              options={modelConfigurations.chime_configurations}
                            />
                            {modelTouched?.right_speaker_chime_config_id && modelErrors?.right_speaker_chime_config_id && (
                              <span className={styles.error}>{modelErrors?.right_speaker_chime_config_id}</span>
                            )}
                          </div>
                        )}
                      </>
                    ) : null}
                    {values.will_update_mcu ? (
                      <>
                        <div className={styles.inputItem}>
                          <label>MCU</label>
                          <Select
                            selectProps={{
                              name: "mcu_model",
                              value: model.mcu_model,
                              onChange: (e) => {
                                setFieldValue(`models_arr[${index}].mcu_firmware_id`, "");
                                setFieldValue(`models_arr[${index}].mcu_model`, e.target.value);
                              },
                            }}
                            options={modelConfigurations.mcu_firmware_versions}
                          />
                          {modelTouched?.mcu_model && modelErrors?.mcu_model && (
                            <span className={styles.error}>{modelErrors?.mcu_model}</span>
                          )}
                        </div>
                        {model.mcu_model ? (
                          <div className={styles.inputItem}>
                            <label>MCU Firmware Version</label>
                            <Select
                              selectProps={{
                                placeholder: "Select Config Version",
                                name: "mcu_firmware_id",
                                value: model.mcu_firmware_id,
                                onChange: (e) => setFieldValue(`models_arr[${index}].mcu_firmware_id`, +e.target.value),
                              }}
                              optionLabel="firmware_version"
                              optionValue="mcu_firmware_id"
                              options={
                                modelConfigList?.data?.mcu_firmware_versions.filter(
                                  (item) =>
                                    item.mcu_model.toLowerCase() === model.mcu_model.toLowerCase() && item.vendor_id === values.vendor_id
                                ) ?? []
                              }
                            />
                            {modelTouched?.mcu_firmware_id && modelErrors?.mcu_firmware_id && (
                              <span className={styles.error}>{modelErrors?.mcu_firmware_id}</span>
                            )}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {values.will_config_amp ? (
                      <>
                        <div className={styles.inputItem}>
                          <label>Amp</label>
                          <Select
                            selectProps={{
                              name: "amp_model",
                              value: model.amp_model,
                              onChange: (e) => {
                                setFieldValue(`models_arr[${index}].customer_amp_config_id`, "");
                                setFieldValue(`models_arr[${index}].amp_model`, e.target.value);
                              },
                            }}
                            options={modelConfigurations.amp_configs}
                          />
                          {modelTouched?.amp_model && modelTouched?.amp_model && (
                            <span className={styles.error}>{modelErrors?.amp_model}</span>
                          )}
                        </div>
                        {model.amp_model ? (
                          <div className={styles.inputItem}>
                            <label>Amp Config Version</label>
                            <Select
                              selectProps={{
                                placeholder: "Select Config Version",
                                name: "customer_amp_config_id",
                                value: model.customer_amp_config_id,
                                onChange: (e) => setFieldValue(`models_arr[${index}].customer_amp_config_id`, +e.target.value),
                              }}
                              optionLabel="config_version"
                              optionValue="customer_amp_config_id"
                              options={
                                modelConfigList?.data?.amp_configurations.filter(
                                  (item) =>
                                    item.amp_model.toLowerCase() === model.amp_model.toLowerCase() && item.vendor_id === values.vendor_id
                                ) ?? []
                              }
                            />
                            {modelTouched?.customer_amp_config_id && modelErrors?.customer_amp_config_id && (
                              <span className={styles.error}>{modelErrors?.customer_amp_config_id}</span>
                            )}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {
                      typeof model.dev_type === "string" &&
                      model.tx_rx === "TX" &&
                      (filteredUseCases.txUseCases.find((item) => item.value === values.use_case_id)?.label === USE_CASES.Express ||
                      values.use_case_id === 0) &&
                      values.models_arr.findIndex((currentModel) => currentModel.tx_rx === ModelTypes.TX) === -1 ||
                      (filteredUseCases.txUseCases.find((item) => item.value === values.use_case_id)?.label === USE_CASES.Express ||
                      values.use_case_id === 0) &&
                      values.models_arr.findIndex((currentModel) => currentModel.tx_rx === ModelTypes.TX) === index
                        && (
                          <ManageProductAddOrEditProductExpressTxConfigurations {...props} modelArrIndex={index} />
                        )
                    }
                    <AccessGuard permission={PERMISSIONS.PRODUCT_MODEL_DELETE}>
                      <Icons.TrashIcon
                        className={styles.trashIcon}
                        onClick={() => {
                          // if the model is empty then remove it from the array
                          // else set the deleteModelId to the index of the model
                          if (isEqual(model, emptyModelObj)) remove(index);
                          else setDeleteModelId(index);
                        }}
                      />
                    </AccessGuard>
                  </div>
                );
              })}
              <AccessGuard permission={PERMISSIONS.PRODUCT_MODEL_ADD}>
                <p
                  className={styles.addAnother}
                  onClick={() => {
                    push({ ...emptyModelObj, use_case_id: values.use_case_id });
                  }}>
                  Add {values.models_arr.length ? "another" : ""} model
                </p>
              </AccessGuard>
              {deleteModelId !== null ? (
                <DeleteConfirmation
                  title="Delete Model"
                  description="Are you sure you want to delete the model?"
                  onConfirmClick={() => {
                    // remove will delete the model from the array and will update the formik values
                    remove(deleteModelId);
                    setDeleteModelId(null);
                  }}
                  onDeclineClick={() => setDeleteModelId(null)}
                />
              ) : null}
            </>
          )}
        </FieldArray>
      </form>
      <ReactTooltip anchorId="mcu_tooltip" place="top" content={TOOLTIP_MESSAGE} />
      <ReactTooltip anchorId="amp_tooltip" place="top" content={TOOLTIP_MESSAGE} />
    </>
  );
};

export default ManageProductAddOrEditProductForm;
