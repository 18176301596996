/**
 * This file contains intefaces that are used for typing local vars
 * All the types that are not related to backend should go here
 */

import { FETCH_PAGE_DATA_END, SET_CONNECTED_PORT, SET_SCAN, SET_SESSION_ID } from "../context/AppContext";
import { COMMAND_STATUS, CUSTOMER_MODULES, ROLE_ID } from "../utils/constants";
import { FTDIDriver } from "../utils/ftdi";
import { I2CDriver } from "../utils/i2c";
import { Serial } from "./serial";
import { USB } from "./usb";
import { FormikProps } from "formik";
import { Customer, ModelTypes, ProductTypes, Role, Sequence, UseCase, UserPermissionResponse } from "./be.interfaces";
import { SerialDriver } from "../utils/serial";

declare global {
  interface Navigator {
    serial: Serial;
    usb: USB;
  }
}

/**
 * ========== Utilities ==========
 */

type UnionKeys<T> = T extends T ? keyof T : never;
type StrictUnionHelper<T, TAll> = T extends any ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>> : never;

// Makes Strict union types for any object, use this to construct conditional object parameters
export type StrictUnion<T> = StrictUnionHelper<T, T>;

export function typeGuard<T>(item: unknown, conditionStr: (keyof T)[]): item is T {
  return item !== undefined && item !== null && typeof item === "object" && conditionStr.every((i) => i in item);
}

/**
 * ========== Utilities End ==========
 */

/**
 * ========== Interfaces ==========
 */

export interface ScanItem {
  key: string;
  timeStamp: number;
  timeStampDiff: number;
}

export interface AppInitState {
  isLoading: boolean;
  pageData: Sequence[] | null;
  error: null;
  user: UserPermissionResponse | null;
  userHasOEMAccess: boolean;
  scan: ScanItem[];
  sessionId: string;
  connectedPort: I2CDriver | FTDIDriver | SerialDriver | null;
}

export interface FetchPageDataAction {
  user: UserPermissionResponse;
  sequence: Sequence[];
  userHasOEMAccess: boolean;
}

export type AppAction =
  | {
      type: typeof FETCH_PAGE_DATA_END;
      payload: FetchPageDataAction;
    }
  | {
      type: typeof SET_SCAN;
      payload: ScanItem[];
    }
  | {
      type: typeof SET_SESSION_ID;
      payload: string;
    }
  | {
      type: typeof SET_CONNECTED_PORT;
      payload: I2CDriver | FTDIDriver | SerialDriver | null;
    };

export type AppContextType = AppInitState & {
  fetchPageData: () => void;
  setSessionId: (token: string) => void;
  setScan: (scan: ScanItem[]) => void;
  setConnectedPort: (port: I2CDriver | FTDIDriver | SerialDriver | null) => void;
};

export interface SendDeviceResponsePayload {
  deviceResponse: string;
  status?: CustomCommandResultStatus;
  wisaProduct: string;
}

export interface CustomSequenceDetailsResponse {
  id?: string;
  title?: string;
  status: COMMAND_STATUS;
  result_type?: CustomCommandResultType;
  deviceResponse?: string;
}
export interface CustomSequenceDetails {
  id: string;
  sequences_id: string;
  title: string;
  sequence_type: CommandTypes;
  set_value: string | null;
  allow_update: boolean | null;
  min_value?: number | null;
  max_value?: number | null;
}

export enum CustomCommandResultType {
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
}

export interface CustomCommandFormValues {
  cust_command_name: string;
  cust_command_description: string;
  result_type: CustomCommandResultType | null;
  move_on: boolean;
  vendor_id: string | null;
  passing_values: string[];
  cust_command_id?: string;
  min_value: number | null;
  max_value: number | null;
}

export enum CommandTypes {
  WISA_COMMANDS = "wisa.wisa_commands",
  WISA_SUB_SEQ = "wisa.wisa_sub_seq",
  CUSTOM_COMMANDS = "input.custom_commands",
}

export enum CustomCommandResultStatus {
  SUCCESS = "SUCCESS",
  FAIL = "FAILED",
}

export interface Log {
  commandName: string;
  value: string;
}

export enum CALIBRATION_SUBMIT_TYPES {
  CALCULATE = "CALCULATE",
  SUBMIT = "SUBMIT",
}

export interface CustomerFormInitValues {
  vendor_id: string;
  customer_name: string;
  manufacturer: boolean;
  customer_info: string;
  sales_account_manager_id: number | null;
  fae_contact_id: number | null;
  customer_point_of_contact_id: number | null;
  basecamp_email_address: string | null;
}

export interface ModelPayload {
  customer_product_model_id?: number;
  customer_product_id: number;
  model_id: string;
  model_description: string;
  use_case?: string;
  use_case_id: number;
  use_case_info: UseCase;
  crossover: string;
  size: string | null;
  tx_rx: ModelTypes;
  mcu_firmware_id: string;
  amp_model: string;
  mcu_model: string;
  customer_amp_config_id: string;
  left_speaker_chime_config_id: string;
  right_speaker_chime_config_id: string;
  positions_required: boolean;
  is_enable?: boolean;
  dev_type: string; // Begin ExpressTxConfigurationsFormModel
  local_speakers: string[];
  operating_system: string;
  os_versions: string[];
  os_other_text: string;
  decode_options: string[];
  audio_hal: string;
  audio_hal_other_text: string;
  binder: boolean;
  rx_configurations: string[];
  sync_requirements: string;
  tsf_available: boolean;
}

export interface ProductFormInitValues {
  customer_product_id?: number;
  product_id: string;
  vendor_id: string;
  product_name: string;
  product_description: string;
  models_arr: ModelPayload[];
  product_type: ProductTypes;
  will_config_amp: boolean;
  will_config_chime: boolean;
  will_update_mcu: boolean;
  map_assc_mode: boolean;
  tx_clock_follower: boolean;
  sub_com_provided: boolean;
  wisa_server: boolean;
  notifications: boolean;
  rx_health: boolean;
  volume_manage_in_apps: boolean;
  software_buttons: boolean;
  small_datagrams: boolean;
  large_datagrams: boolean;
  energy_star: boolean;
  time_out_duration: number | null;
  region_code_arr: string[];
  use_case_id: number;
  other_region?: string | null;
  other?: boolean;
  version_id: string | null;
  sample_rate: number;
  bits_per_frame_sample: number;
  pcm_format: number;
  will_certify: boolean;
}

export interface DeviceConfigurationPayload {
  vendor_id: string;
  product_id: number;
  customer_product_model_id: number;
  speaker_position?: string;
  serial_number: string;
  region?: string | null;
}

export interface ContextDetails {
  vendor_id: string | null;
  customer_product_id: number | null;
  model_id: string | null;
  serial_number: string;
}
export interface AssignVerifyPayload {
  context_details: ContextDetails | null;
  wisa_mac_address: string;
  device_response: string;
}

export enum NavigationRoutesEnum {
  HOME = "/",
  AMP_CONFIGURATION_ROUTE = "amp-configuration",
  MANUAL_CALIBRATION = "/manual-calibration",
  CREATE_PRODUCT = "/manage-product/create",
  CUSTOM_SEQUENCE_EXECUTION = "/run-custom-seq/:id",
  MANAGE_CUSTOM_COMMAND = "/manage-custom-commands",
  MANAGE_CUSTOM_SEQUENCE = "/manage-custom-seq",
  MANAGE_CUSTOMER = "/manage-customer",
  MANAGE_FIRMWARE_ROUTE = "manage-firmware",
  MANAGE_PRODUCT = "/manage-product",
  MCU_FIRMWARE_ROUTE = "mcu-firmware",
  RUN_CUSTOM_SEQUENCE = "/run-custom-seq",
  UPDATE_CUSTOM_SEQUENCE = "/update-custom-seq/:id",
  UPDATE_PRODUCT = "/manage-product/update/:id",
  WISA_FIRMWARE_ROUTE = "wisa-firmware",
  CHIME_CONFIGURATION_ROUTE = "chime-configuration",
}

export interface ManageFirmwareWisaFirmwareFormInitValuesInterface {
  current_customer_version: number | null;
  description: string | null;
  firmware_bin: File | null;
  memory_location: string;
  version: string;
  wisa_product: string;
  file_name?: string;
  version_id?: number;
}

export interface SelectOption<T> {
  label: string;
  value: T;
}

export interface CustomSequencePayload {
  seq_name: string;
  vendor_id: string | null;
}

export interface CopyModelData {
  customerProductId: number;
  customerProductModelId: number;
  productId: number;
  modelId: string;
  vendorId: string;
  productType: ProductTypes;
}

export interface CopyModelFormValues {
  new_vendor_id: string | null;
  new_product_id: number | null;
  model_id: string | null;
}

export interface UserFormInitValues {
  user_id?: number;
  vendor_id: string;
  name: string;
  email: string;
  role_id: ROLE_ID;
  password?: string;
  role: Role | null;
  customer: Customer | null;
  isPasswordFieldVisible?: boolean;
  confirmPassword?: string;
  is_enable?: boolean;
  customer_point_of_contact: boolean;
  manage_customer_id: string | null;
  managed_product_ids: number[];
}

export interface UserPayload {
  user_id?: number;
  vendor_id: string;
  name: string;
  email: string;
  role_id: string;
  password?: string;
  customer_point_of_contact: boolean;
  manage_customer_id: string | null;
  managed_product_ids: number[];
}

export interface UserFormProps extends FormikProps<UserFormInitValues> {
  isEditForm: boolean;
}

export interface AmpConfigInitValue {
  config_description: string;
  config_version: string;
  vendor_id: string;
  config_file_name?: string;
  current_customer_version: boolean;
  amp_config_bin?: File | null;
  amp_model: string;
}

export interface AmpConfigPayload extends AmpConfigInitValue {
  customer_amp_config_id: number;
}

export interface McuFirmwareVersionInitValue {
  mcu_firmware_id?: number;
  vendor_id: string;
  firmware_version: string;
  firmware_description: string;
  firmware_file_name?: string;
  mcu_firmware_bin?: File | null;
  mcu_model: string;
  current_customer_version: boolean;
}

export interface McuFirmwareVersionPayload extends McuFirmwareVersionInitValue {
  mcu_firmware_id: number;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export enum CommunicationType {
  I2C = "I2C",
  FTDI = "FTDI",
  SERIAL = "SERIAL",
}

export interface CustomerModule {
  vendor_id: string;
  modules: CUSTOMER_MODULES[];
}

export interface ChimeConfigInitValue {
  chime_description: string;
  chime_version: string;
  vendor_id: string;
  file_name?: string;
  current_customer_version: boolean;
  chime_config_bin?: File | null;
  chime_audio: string;
}

export interface ChimeConfigPayload extends ChimeConfigInitValue {
  customer_chime_config_id: number;
}

export interface ModelConfigList {
  amp_configs: SelectOption<string | null>[];
  mcu_firmware_versions: SelectOption<string | null>[];
  chime_configurations: SelectOption<string | null>[];
}

export interface TxI2sFormat {
  pcm_format: number;
  bits_per_frame_sample: number;
  sample_rate: number;
}

export interface ExpressTxConfigurationsFormModel {
  dev_type: string;
  local_speakers: string[];
  operating_system: string;
  os_versions: string[];
  os_other_text: string;
  decode_options: string[];
  audio_hal: string;
  audio_hal_other_text: string;
  binder: boolean;
  rx_configurations: string[];
  sync_requirements: string;
  tsf_available: boolean;
}

export type ExpressTxConfigurationsModel = Exclude<
  keyof ExpressTxConfigurationsFormModel,
  "binder" | "audio_hal" | "operating_system" | "dev_type" | "sync_requirements" | "tsf_available"
>;

export interface ManageCertAppVersionFormInitValues {
  version_no: string;
  description: string;
  file: File | null;
  is_latest_version: boolean;
}

/**
 * ========== Interfaces End ==========
 */
