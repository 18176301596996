import { AxiosResponse } from "axios";
import { Dispatch, Fragment, SetStateAction, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Icons } from "../../../../assets/images";
import { deleteModel, enableDisableModel, enableDisableProduct } from "../../../api/config";
import { http } from "../../../api/utils/http";
import AddOrEditModel from "../../../components/AddOrEditModel";
import DeleteConfirmation from "../../../components/ConfirmationDialog";
import CopyModel from "../../../components/CopyModel";
import CopyProduct from "../../../components/CopyProduct";
import AccessGuard from "../../../components/Guards/AccessGuard";
import Switch from "../../../components/Switch";
import { initialContext, useCustomerContext } from "../../../hooks/useCustomerContext";
import { RunOptions, useFetch } from "../../../hooks/useFetch";
import { CustomerProduct, Model, Product, ProductTypes, UpdateItemResponse, UseCase } from "../../../interfaces/be.interfaces";
import { CopyModelData, ModelPayload } from "../../../interfaces/fe.interfaces";
import { DESC_CHAR_LIMIT, PERMISSIONS } from "../../../utils/constants";
import { emptyModelObj } from "../ManageProductCreate";
import styles from "./styles.module.scss";

interface ProductInfoProps {
  title: string;
  desc: string;
  product_id: number;
  handleStatusUpdateChange: (id: number) => void;
  status: boolean;
  vendor_id: string;
  id: number;
  use_case: UseCase;
  setModelDetails: (model: ModelPayload | null) => void;
  setProductUseCaseId: Dispatch<SetStateAction<number | null>>;
  productType: ProductTypes;
  setProductType: Dispatch<SetStateAction<ProductTypes | null>>;
  setProductWillUpdateMcu: Dispatch<SetStateAction<boolean>>;
  setProductWillConfigAmp: Dispatch<SetStateAction<boolean>>;
  setProductWillConfigChime: Dispatch<SetStateAction<boolean>>;
  setVendorId: Dispatch<SetStateAction<string | null>>;
  runProductList: (options?: RunOptions) => void;
  versionInfo: string | undefined;
  willConfigAmp: boolean;
  willConfigChime: boolean;
  willUpdateMcu: boolean;
}

interface CustomerItemProps {
  customer: CustomerProduct;
  runProductList: (options?: RunOptions | undefined) => void;
}

/**
 * Creates UI for product info section
 * takes in title, description, product id, status and vendor id as props
 */
const ProductInfo = (props: ProductInfoProps) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);

  return (
    <div className={styles.productName}>
      <div className={styles.textContainer}>
        <div className="d-flex align-item-center">
          <p>
            {props?.title} (
            <span>{`Product Type: ${props?.productType}, Product ID: ${props?.product_id}${
              props.versionInfo ? `, Version: ${props.versionInfo}` : ""
            }`}</span>
            )
          </p>
          <AccessGuard permission={PERMISSIONS.PRODUCT_UPDATE}>
            <Icons.EditIcon
              onClick={() => {
                navigate(`/manage-product/update/${props?.id}`);
              }}
              className={styles.editIcon}
            />
          </AccessGuard>
          <AccessGuard permission={PERMISSIONS.PRODUCT_ADD}>
            <Icons.CopyIcon
              className={styles.editIcon}
              onClick={() => {
                setShowCopyPopup(true);
              }}
              title="Copy Product"
            />
          </AccessGuard>
          <AccessGuard permission={PERMISSIONS.PRODUCT_ENABLE_DISABLE}>
            <div className={styles.enableDisableContainer}>
              <Switch checked={props?.status} onChange={() => props?.handleStatusUpdateChange(props?.id)} />
            </div>
          </AccessGuard>
        </div>
        <p>
          {props?.desc?.slice(0, showMore ? props?.desc?.length : DESC_CHAR_LIMIT)}
          {props?.desc?.length > DESC_CHAR_LIMIT && !showMore ? "..." : ""}
          {props?.desc?.length > DESC_CHAR_LIMIT ? (
            <span className={styles.seeMore} onClick={() => setShowMore(!showMore)}>
              show {showMore ? "less" : "more"}
            </span>
          ) : (
            ""
          )}
        </p>
      </div>
      <AccessGuard permission={PERMISSIONS.PRODUCT_MODEL_ADD}>
        <>
          <button
            onClick={() => {
              props.setModelDetails({
                ...emptyModelObj,
                customer_product_id: props?.id,
                use_case_info: props.use_case,
                use_case_id: props.use_case?.use_case_id,
                mcu_firmware_id: "",
                customer_amp_config_id: "",
                amp_model: "",
                mcu_model: "",
                left_speaker_chime_config_id: "",
                right_speaker_chime_config_id: "",
                positions_required: false,
                dev_type: "",
                local_speakers: [],
                operating_system: "",
                os_versions: [],
                os_other_text: "",
                decode_options: [],
                audio_hal: "",
                audio_hal_other_text: "",
                binder: false,
                rx_configurations: [],
                sync_requirements: "",
                tsf_available: false,
              });
              props.setProductWillUpdateMcu(props.willUpdateMcu);
              props.setProductWillConfigAmp(props.willConfigAmp);
              props.setProductWillConfigChime(props.willConfigChime);
              props.setVendorId(props.vendor_id);
              props.setProductUseCaseId(props.use_case?.use_case_id);
              props.setProductType(props.productType);
            }}>
            Add Model
          </button>
          {showCopyPopup && (
            <CopyProduct
              show={showCopyPopup}
              setShow={setShowCopyPopup}
              customerProductId={props.id}
              vendorId={props.vendor_id}
              refreshList={props.runProductList}
            />
          )}
        </>
      </AccessGuard>
    </div>
  );
};

/**
 * Creates UI for customer item section
 * takes in customer object as props
 */
const CustomerItem = ({ customer, runProductList }: CustomerItemProps) => {
  const navigate = useNavigate();
  const { context, updateContext } = useCustomerContext();
  const [apiError, setAPIError] = useState<string>();
  const [deleteModelDetails, setDeleteModelDetails] = useState<Model | null>(null);
  const [productWillConfigAmp, setProductWillConfigAmp] = useState(false);
  const [productWillConfigChime, setProductWillConfigChime] = useState(false);
  const [productWillUpdateMcu, setProductWillUpdateMcu] = useState(false);
  const [vendorId, setVendorId] = useState<string | null>(null);
  const [modelDetails, setModelDetails] = useState<ModelPayload | null>(null);
  const [productUseCaseId, setProductUseCaseId] = useState<number | null>(null);
  const [productType, setProductType] = useState<ProductTypes | null>(null);
  const [modelToCopyInfo, setModelToCopyInfo] = useState<CopyModelData | null>(null);

  // Hook to update the status of the product
  const { run: runStatusUpdate, error: statusUpdateError } = useFetch<UpdateItemResponse<Product>>({
    url: enableDisableProduct,
    runOnMount: false,
    onSuccess: ({ data: res }) => {
      toast.success(res?.message);

      // when product is disabled, reset the context
      if (context.customerProductId === res.data.customer_product_id && !res.data.is_enable) {
        updateContext(initialContext);
      }

      // if successful then re-run the product query to get the updated list
      runProductList({
        showLoader: false,
      });
    },
  });

  // Hook to update the status of the model
  const { run: runModelStatusUpdate, error: modelStatusUpdateError } = useFetch<UpdateItemResponse<Model>>({
    url: enableDisableModel,
    runOnMount: false,
    onSuccess: ({ data: res }) => {
      toast.success(res?.message);

      // When model is disabled, reset the context
      if (context.customerProductModelId === res.data.customer_product_model_id && !res.data.is_enable) {
        updateContext(initialContext);
      }

      // if successful then re-run the product query to get the updated list
      runProductList({
        showLoader: false,
      });
    },
  });

  /**
   * Function to update the status of the product
   * @param id - customer_product_id
   */
  const handleStatusUpdateChange = useCallback(
    (id: number) => {
      // call the hook to update the status of the product
      runStatusUpdate({
        url: `${enableDisableProduct}/${id}`,
      });
    },
    [runStatusUpdate]
  );

  /**
   * Function to update the status of the model
   * @param customerProductModelId - customer_product_model_id
   */
  const handleModelStatusUpdateChange = useCallback(
    (customerProductModelId: number) => {
      // call the hook to update the status of the model
      runModelStatusUpdate({
        url: `${enableDisableModel}/${customerProductModelId}`,
      });
    },
    [runModelStatusUpdate]
  );

  const handleDeleteModel = useCallback(() => {
    const onSuccess = (response: AxiosResponse<UpdateItemResponse>) => {
      if (!deleteModelDetails) return;

      if (response.data.status) {
        toast.success("Model deleted successfully");
        runProductList({
          showLoader: false,
        });

        if (deleteModelDetails.customer_product_model_id === context.customerProductModelId) {
          updateContext(initialContext);
        }
      } else {
        setAPIError(response.data.message);
      }
    };

    http.makeDeleteRequest<UpdateItemResponse, { messages: string }>(
      `${deleteModel}/${deleteModelDetails?.customer_product_model_id}`,
      onSuccess,
      (e) => {
        setAPIError(e?.response?.data?.messages);
      }
    );
  }, [deleteModelDetails, context]);

  const error = modelStatusUpdateError || statusUpdateError || apiError;

  return (
    <>
      {error ? <div className="errorAlert">{error}</div> : null}
      <div className={styles.listContainer}>
        <div className={styles.customerName}>
          <p>
            {customer?.customer_name} ({customer.vendor_id})
          </p>
          <AccessGuard permission={PERMISSIONS.PRODUCT_ADD}>
            <button onClick={() => navigate("create", { state: { vendor_id: customer.vendor_id, customer_name: customer.customer_name } })}>
              Add Product
            </button>
          </AccessGuard>
        </div>
        <div>
          {customer?.customer_products?.length ? (
            <>
              {customer?.customer_products?.map((product) => (
                <Fragment key={product.customer_product_id}>
                  <ProductInfo
                    id={product.customer_product_id}
                    title={product.product_name}
                    desc={product.product_description}
                    product_id={product.product_id}
                    status={product.is_enable}
                    vendor_id={product.vendor_id}
                    use_case={product.use_case_info}
                    handleStatusUpdateChange={handleStatusUpdateChange}
                    setModelDetails={setModelDetails}
                    setProductUseCaseId={setProductUseCaseId}
                    productType={product.product_type}
                    setProductType={setProductType}
                    willConfigChime={product.will_config_chime}
                    setProductWillConfigChime={setProductWillConfigChime}
                    runProductList={runProductList}
                    setProductWillConfigAmp={setProductWillConfigAmp}
                    setVendorId={setVendorId}
                    setProductWillUpdateMcu={setProductWillUpdateMcu}
                    willConfigAmp={product.will_config_amp}
                    versionInfo={product.customer_product_versions[0]?.version_details?.version}
                    willUpdateMcu={product.will_update_mcu}
                  />
                  <div className={styles.tableResponsive}>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th>Model ID</th>
                          <th>Model Description</th>
                          <th>Use Case</th>
                          <th>Crossover</th>
                          <th>Size</th>
                          <AccessGuard
                            permission={[
                              PERMISSIONS.PRODUCT_MODEL_UPDATE,
                              PERMISSIONS.PRODUCT_MODEL_DELETE,
                              PERMISSIONS.PRODUCT_MODEL_ADD,
                              PERMISSIONS.PRODUCT_MODEL_ENABLE_DISABLE,
                            ]}>
                            <th className={styles.action}>Action</th>
                          </AccessGuard>
                        </tr>
                      </thead>
                      <tbody>
                        {product?.customer_product_models?.length === 0 ? (
                          <tr>
                            <td colSpan={6} className={styles.noDataFound}>
                              No Models Found
                            </td>
                          </tr>
                        ) : (
                          <>
                            {product?.customer_product_models?.map((model) => (
                              <tr key={model?.model_id}>
                                <td>{model?.model_id}</td>
                                <td>{model?.model_description}</td>
                                <td>{model?.use_case_info?.use_case}</td>
                                <td>{model?.crossover}</td>
                                <td>{model?.size || "-"}</td>
                                <AccessGuard
                                  permission={[
                                    PERMISSIONS.PRODUCT_MODEL_UPDATE,
                                    PERMISSIONS.PRODUCT_MODEL_DELETE,
                                    PERMISSIONS.PRODUCT_MODEL_ADD,
                                    PERMISSIONS.PRODUCT_MODEL_ENABLE_DISABLE,
                                  ]}>
                                  <td className={styles.actionCell}>
                                    <AccessGuard permission={PERMISSIONS.PRODUCT_MODEL_UPDATE}>
                                      <Icons.EditIcon
                                        onClick={() => {
                                          setModelDetails({
                                            ...model,
                                            ...model.express_tx_configuration,
                                            customer_amp_config_id: String(
                                              model?.customer_product_model_amp_config?.customer_amp_config_id ?? ""
                                            ),
                                            mcu_firmware_id: String(model.customer_product_model_mcu_firmware?.mcu_firmware_id ?? ""),
                                            amp_model: model?.customer_product_model_amp_config?.amp_config?.amp_model ?? "",
                                            mcu_model: model?.customer_product_model_mcu_firmware?.mcu_firmware?.mcu_model ?? "",
                                            left_speaker_chime_config_id:
                                              String(model?.customer_product_model_chime_config?.left_speaker_chime_config_id) ?? "",
                                            right_speaker_chime_config_id:
                                              String(model?.customer_product_model_chime_config?.right_speaker_chime_config_id) ?? "",
                                            positions_required: model?.use_case_info?.positions_required ?? false,
                                          });
                                          setVendorId(product.vendor_id);
                                          setProductWillConfigAmp(product.will_config_amp);
                                          setProductWillConfigChime(product.will_config_chime);
                                          setProductWillUpdateMcu(product.will_update_mcu);
                                          setProductUseCaseId(product.use_case_id);
                                          setProductType(product.product_type);
                                        }}
                                      />
                                    </AccessGuard>
                                    <AccessGuard permission={PERMISSIONS.PRODUCT_MODEL_ADD}>
                                      <Icons.CopyIcon
                                        onClick={() => {
                                          setModelToCopyInfo({
                                            modelId: model.model_id,
                                            productId: product.product_id,
                                            vendorId: product.vendor_id,
                                            productType: product.product_type,
                                            customerProductId: product.customer_product_id,
                                            customerProductModelId: model.customer_product_model_id,
                                          });
                                        }}
                                      />
                                    </AccessGuard>
                                    <AccessGuard permission={PERMISSIONS.PRODUCT_MODEL_DELETE}>
                                      <Icons.TrashIcon
                                        onClick={() => {
                                          setDeleteModelDetails(model);
                                        }}
                                      />
                                    </AccessGuard>
                                    <AccessGuard permission={PERMISSIONS.PRODUCT_MODEL_ENABLE_DISABLE}>
                                      <Switch
                                        checked={model.is_enable}
                                        onChange={() => handleModelStatusUpdateChange(model.customer_product_model_id)}
                                      />
                                    </AccessGuard>
                                  </td>
                                </AccessGuard>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Fragment>
              ))}
            </>
          ) : (
            <>
              <div className={styles.noDataFound}>No Products Found</div>
            </>
          )}
        </div>
      </div>
      {deleteModelDetails ? (
        <DeleteConfirmation
          title="Delete Model"
          description="Are you sure you want to delete the model?"
          onConfirmClick={() => {
            handleDeleteModel();
            setDeleteModelDetails(null);
          }}
          onDeclineClick={() => setDeleteModelDetails(null)}
        />
      ) : null}
      {modelDetails && productUseCaseId && productType && vendorId ? (
        <AddOrEditModel
          modelDetails={modelDetails}
          onSuccess={() => {
            runProductList({
              showLoader: false,
            });
          }}
          onClose={() => {
            setModelDetails(null);
            setProductUseCaseId(null);
          }}
          productWillConfigAmp={productWillConfigAmp}
          productWillUpdateMcu={productWillUpdateMcu}
          productWillConfigChime={productWillConfigChime}
          vendorId={vendorId}
          productUseCaseId={productUseCaseId}
          productType={productType}
        />
      ) : null}
      {modelToCopyInfo ? (
        <CopyModel copyModelInfo={modelToCopyInfo} setCopyModelInfo={setModelToCopyInfo} refreshList={runProductList} />
      ) : null}
    </>
  );
};

export default CustomerItem;
