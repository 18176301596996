import { Formik, FormikHelpers } from "formik";
import styles from "./styles.module.scss";
import Form from "./Form";
import { validateCustomer } from "../../utils/validationSchemas";
import { CustomerFormInitValues } from "../../interfaces/fe.interfaces";
import { http } from "../../api/utils/http";
import { PostOrPutCustomerResponse } from "../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { editCustomer } from "../../api/config";
import { useCustomerContext } from "../../hooks/useCustomerContext";

interface EditCustomerDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
  initialValues: CustomerFormInitValues;
  id: string;
}

const EditCustomerDialog = (props: EditCustomerDialogProps) => {
  const { context, updateContext } = useCustomerContext();

  const handleEditCustomer = (values: CustomerFormInitValues, { setSubmitting }: FormikHelpers<CustomerFormInitValues>): void => {
    // vendor_id is old vendor_id for which we are editing the record,
    // If we are changing the vendor_id, then new_vendor_id will be the new vendor_id
    const payload = {
      vendor_id: props?.initialValues?.vendor_id,
      new_vendor_id: values.vendor_id,
      customer_name: values.customer_name,
      manufacturer: values.manufacturer,
      customer_info: values.customer_info,
      basecamp_email_address: values.basecamp_email_address,
      customer_point_of_contact_id: values.customer_point_of_contact_id,
      fae_contact_id: values.fae_contact_id,
      sales_account_manager_id: values.sales_account_manager_id,
    };

    // Success callback for /edit-customer API
    const onSuccess = ({ data: response }: AxiosResponse<PostOrPutCustomerResponse>) => {
      setSubmitting(false);
      if (response?.status) {
        if (context?.vendorId === props?.initialValues?.vendor_id) {
          updateContext({ ...context, vendorId: values.vendor_id, customerName: values.customer_name });
        }

        toast.success(response?.message);

        // Close the dialog and call parent callback to refresh the table
        props.callback();
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /edit-customer API
    http.makePutRequest<PostOrPutCustomerResponse, { message: string }>(
      editCustomer,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      payload
    );
  };

  return (
    <>
      <div className={styles.dialogContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={props?.initialValues}
          onSubmit={handleEditCustomer}
          onReset={props.onCancelClick}
          validationSchema={validateCustomer}>
          {(props) => <Form {...props} enableReinitialize={true} />}
        </Formik>
      </div>
    </>
  );
};

export default EditCustomerDialog;
