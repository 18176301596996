import { FormikProps } from "formik";
import { useCallback, useMemo, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { GetModelConfigListResponse, ModelTypes, ModelUseCase, ProductTypes } from "../../interfaces/be.interfaces";
import { ModelConfigList, ModelPayload, SelectOption } from "../../interfaces/fe.interfaces";
import { destructModelConfigList, handleAlphaNumInputChange, handleNumberInputChange } from "../../utils/helpers";
import styles from "../AddOrEditProductForm/styles.module.scss";
import Select from "../Select";
import { getModelConfigurationList } from "../../api/config";

interface AddOrEditModelFormProps extends FormikProps<ModelPayload> {
  productUseCaseId: number;
  sizeList: SelectOption<string>[];
  useCaseList: ModelUseCase | null;
  productType: ProductTypes;
  onClose: () => void;
  productWillUpdateMcu: boolean;
  productWillConfigAmp: boolean;
  productWillConfigChime: boolean;
  vendorId: string;
}

interface SelectUseCase {
  label: string;
  value: number;
  positions_required: boolean;
}

/**
 * Form component for add/edit model
 */
const AddOrEditModelForm = ({
  values,
  setFieldValue,
  handleSubmit,
  handleBlur,
  handleChange,
  touched,
  errors,
  isSubmitting,
  productUseCaseId,
  sizeList,
  useCaseList,
  productType,
  onClose,
  productWillUpdateMcu,
  productWillConfigAmp,
  productWillConfigChime,
  vendorId,
}: AddOrEditModelFormProps) => {
  const [modelConfigurations, setModelConfigurations] = useState<ModelConfigList>({
    amp_configs: [],
    chime_configurations: [],
    mcu_firmware_versions: [],
  });

  // Filters use cases based on product type and tx/rx
  const filteredUseCases = useMemo(() => {
    const txUseCases: SelectUseCase[] = [];
    const rxUseCases: SelectUseCase[] = [];

    useCaseList?.data?.forEach((item) => {
      const obj = { label: item.use_case, value: item.use_case_id, positions_required: item.positions_required };

      // push to array only of use case type is same as selected product type.
      if (item.product_type === productType) {
        // filter based on tx/rx
        if (item.tx_rx === ModelTypes.TX) {
          txUseCases.push(obj);
        } else {
          rxUseCases.push(obj);
        }
      }
    });

    return {
      txUseCases,
      rxUseCases,
    };
  }, [useCaseList, productType]);

  /**
   * Fetch list of amp configuration, chime config and MCU firmware versions to show in dropdown
   * only active amp configuration, chime config and MCU firmware versions are displayed in dropdown
   */
  const { data: modelConfigList } = useFetch<GetModelConfigListResponse>({
    runOnMount: true,
    url: getModelConfigurationList,
    onSuccess: (response) => {
      const lists = destructModelConfigList(response.data?.data, vendorId);

      setModelConfigurations(lists);
    },
  });

  // Use when change in model type
  const onChangeModelType = useCallback(
    (value: string) => {
      setFieldValue("tx_rx", value);
      setFieldValue("use_case_id", "");

      // Events when user select 'TX' as model type
      if (value === ModelTypes.TX) {
        setFieldValue("use_case_id", productUseCaseId);
        setFieldValue("crossover", 0);
        setFieldValue("size", "");
      }
    },
    [productUseCaseId]
  );

  // Handle amp config model change
  const onChangeAmpConfigModel = useCallback((value: string) => {
    setFieldValue("amp_model", value);
    setFieldValue("customer_amp_config_id", "");
  }, []);

  // Handle mcu firmware model change
  const onChangeMcuModel = useCallback((value: string) => {
    setFieldValue("mcu_model", value);
    setFieldValue("mcu_firmware_id", "");
  }, []);

  const onChangeUseCase = useCallback(
    (value: number) => {
      setFieldValue("use_case_id", value);

      // Choosing current model use cases
      const useCases = values.tx_rx === ModelTypes.TX ? filteredUseCases.txUseCases : filteredUseCases.rxUseCases;

      // Selecting "positions_required" for chime configuration
      setFieldValue("positions_required", useCases.find((item) => item.value === value)?.positions_required || false);
    },
    [filteredUseCases, values.tx_rx]
  );

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className={`${styles.addModelContainer}`}>
        <div className={styles.inputItem}>
          <label>Model ID</label>
          <input
            type="text"
            placeholder="Enter model ID"
            value={values.model_id}
            name="model_id"
            onBlur={handleBlur}
            onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
          />
          {touched?.model_id && errors?.model_id && <span className={styles.error}>{errors?.model_id}</span>}
        </div>
        <div className={styles.inputItem}>
          <label>Model Description</label>
          <textarea
            rows={1}
            placeholder="Enter Description"
            value={values.model_description}
            name="model_description"
            onChange={handleChange}
            onBlur={handleBlur}></textarea>
          {touched?.model_description && errors?.model_description && <span className={styles.error}>{errors?.model_description}</span>}
        </div>
        <div className={styles.inputItem}>
          <label>Model type?</label>
          <Select
            selectProps={{
              name: "tx_rx",
              value: values.tx_rx || "",
              placeholder: "Select model type",
              onChange: (event) => onChangeModelType(event.target.value),
            }}
            options={[
              { label: "Transmitter", value: ModelTypes.TX },
              { label: "Receiver", value: ModelTypes.RX },
            ]}
          />
          {touched?.tx_rx && errors?.tx_rx && <span className={styles.error}>{errors?.tx_rx}</span>}
        </div>
        <div className={`${styles.inputItem}`}>
          <label>Cross Over(Hz)</label>
          <input
            type="text"
            placeholder="Enter cross over point"
            value={values.crossover}
            name="crossover"
            onBlur={handleBlur}
            onChange={(e) => handleNumberInputChange(e, setFieldValue)}
            disabled={values.tx_rx === ModelTypes.TX}
          />
          {touched?.crossover && errors?.crossover && <span className={styles.error}>{errors?.crossover}</span>}
        </div>
        <div className={styles.inputItem}>
          <label>Use case</label>
          <Select
            selectProps={{
              name: "use_case_id",
              value: values.use_case_id || "",
              placeholder: "Select use case",
              disabled: values.tx_rx === ModelTypes.TX,
              onChange: (e) => onChangeUseCase(+e.target.value),
            }}
            options={values.tx_rx === ModelTypes.TX ? filteredUseCases.txUseCases : filteredUseCases.rxUseCases}
          />
          {touched?.use_case_id && errors?.use_case_id && <span className={styles.error}>{errors?.use_case_id}</span>}
        </div>
        <div className={styles.inputItem}>
          <label>Size</label>
          <Select
            selectProps={{
              name: "size",
              value: values.size || "",
              placeholder: "Select Model Size",
              disabled: values.tx_rx === ModelTypes.TX,
              onChange: (event) => setFieldValue("size", event.target.value),
            }}
            options={sizeList}
          />
          {touched?.size && errors?.size && <span className={styles.error}>{errors?.size}</span>}
        </div>
        {productWillConfigChime ? (
          <>
            <div className={styles.inputItem}>
              <label>Left chime config version</label>
              <Select
                selectProps={{
                  name: "left_speaker_chime_config_id",
                  value: values.left_speaker_chime_config_id,
                  onChange: (event) => setFieldValue("left_speaker_chime_config_id", event.target.value),
                }}
                options={modelConfigurations.chime_configurations}
              />
              {touched?.left_speaker_chime_config_id && errors?.left_speaker_chime_config_id && (
                <span className={styles.error}>{errors?.left_speaker_chime_config_id}</span>
              )}
            </div>
            {values.positions_required && (
              <div className={styles.inputItem}>
                <label>Right chime config version</label>
                <Select
                  selectProps={{
                    name: "right_speaker_chime_config_id",
                    value: values.right_speaker_chime_config_id,
                    onChange: (event) => setFieldValue("right_speaker_chime_config_id", event.target.value),
                  }}
                  options={modelConfigurations.chime_configurations}
                />
                {touched?.right_speaker_chime_config_id && errors?.right_speaker_chime_config_id && (
                  <span className={styles.error}>{errors?.right_speaker_chime_config_id}</span>
                )}
              </div>
            )}
          </>
        ) : null}
        {productWillUpdateMcu ? (
          <>
            <div className={styles.inputItem}>
              <label>MCU</label>
              <Select
                selectProps={{
                  name: "mcu_model",
                  value: values.mcu_model,
                  onChange: (event) => onChangeMcuModel(event.target.value),
                }}
                options={modelConfigurations.mcu_firmware_versions}
              />
              {touched?.mcu_model && errors?.mcu_model && <span className={styles.error}>{errors?.mcu_model}</span>}
            </div>
            {values.mcu_model ? (
              <div className={styles.inputItem}>
                <label>MCU Firmware Version</label>
                <Select
                  selectProps={{
                    placeholder: "Select Config Version",
                    name: "mcu_firmware_id",
                    value: values.mcu_firmware_id,
                    onChange: (event) => setFieldValue("mcu_firmware_id", +event.target.value),
                  }}
                  optionLabel="firmware_version"
                  optionValue="mcu_firmware_id"
                  options={
                    modelConfigList?.data?.mcu_firmware_versions.filter(
                      (item) => item.mcu_model.toLowerCase() === values.mcu_model.toLowerCase() && item.vendor_id === vendorId
                    ) ?? []
                  }
                />
                {touched?.mcu_firmware_id && errors?.mcu_firmware_id && <span className={styles.error}>{errors?.mcu_firmware_id}</span>}
              </div>
            ) : null}
          </>
        ) : null}
        {productWillConfigAmp ? (
          <>
            <div className={styles.inputItem}>
              <label>Amp</label>
              <Select
                selectProps={{
                  name: "amp_model",
                  value: values.amp_model,
                  onChange: (event) => onChangeAmpConfigModel(event.target.value),
                }}
                options={modelConfigurations.amp_configs}
              />
              {touched?.amp_model && errors?.amp_model && <span className={styles.error}>{errors?.amp_model}</span>}
            </div>
            {values.amp_model ? (
              <div className={styles.inputItem}>
                <label>Amp Config Version</label>
                <Select
                  selectProps={{
                    placeholder: "Select Config Version",
                    name: "customer_amp_config_id",
                    value: values.customer_amp_config_id,
                    onChange: (event) => setFieldValue("customer_amp_config_id", +event.target.value),
                  }}
                  optionLabel="config_version"
                  optionValue="customer_amp_config_id"
                  options={
                    modelConfigList?.data?.amp_configurations.filter(
                      (item) => item.amp_model.toLowerCase() === values.amp_model.toLowerCase() && item.vendor_id === vendorId
                    ) ?? []
                  }
                />
                {touched?.customer_amp_config_id && errors?.customer_amp_config_id && (
                  <span className={styles.error}>{errors?.customer_amp_config_id}</span>
                )}
              </div>
            ) : null}
          </>
        ) : null}
        <div className={styles.btnContainer}>
          <button disabled={isSubmitting}>Save</button>
          <button type="button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddOrEditModelForm;
