import styles from "./styles.module.scss";
import { FormikProps, getIn } from "formik";
import { ChangeEvent, useCallback } from "react";
import { ExpressTxConfigurationsModel, ProductFormInitValues } from "../../../interfaces/fe.interfaces";
import { EXPRESS_DECODE_OPTIONS_ENUM, LOCAL_SPEAKERS_ENUM } from "../../../utils/constants";
import Select from "../../../components/Select";
import Checkbox from "../../../components/Checkbox";

export interface ManageProductAddOrEditProductExpressTxConfigurationsCustomerHardwareProps extends FormikProps<ProductFormInitValues> {
  modelArrIndex: number;
}

const ManageProductAddOrEditProductExpressTxConfigurationsCustomerHardware = ({
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  handleBlur,
  modelArrIndex,
}: ManageProductAddOrEditProductExpressTxConfigurationsCustomerHardwareProps) => {
  const {
    dev_type,
    local_speakers,
    operating_system,
    os_versions,
    os_other_text,
    decode_options,
    audio_hal,
    audio_hal_other_text,
    binder,
  } = values.models_arr[modelArrIndex];

  const isValidationPassed = useCallback(
    (fieldName: string) => {
      return getIn(touched, `models_arr[${modelArrIndex}].${fieldName}`) && getIn(errors, `models_arr[${modelArrIndex}].${fieldName}`);
    },
    [errors, touched, modelArrIndex, values]
  );

  const getError = useCallback(
    (fieldName: string) => getIn(errors, `models_arr[${modelArrIndex}].${fieldName}`),
    [errors, touched, modelArrIndex]
  );

  const haveSelectedCheckboxValue = useCallback(
    (checkboxName: ExpressTxConfigurationsModel, checkboxValue: string) => {
      return values.models_arr[modelArrIndex][checkboxName]?.includes(checkboxValue) ?? false;
    },
    [values.models_arr, modelArrIndex]
  );

  const handleSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setFieldValue(`models_arr[${modelArrIndex}].${e.target.name}`, e.target.value);
    },
    [setFieldValue, modelArrIndex]
  );

  const hanldeRadioChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let val: string | boolean = e.target.value;
      if (val === "true") {
        val = true;
      } else if (val === "false") {
        val = false;
      }
      setFieldValue(`models_arr[${modelArrIndex}].${e.target.name}`, val);
    },
    [setFieldValue, modelArrIndex]
  );

  const handleLocalSpeakersCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let currentLocalSpeakers: string[] = local_speakers;
      const isChecked = e.target.checked;
      if (isChecked) {
        currentLocalSpeakers = local_speakers ? [...local_speakers, e.target.name] : [e.target.name];
      } else {
        currentLocalSpeakers = local_speakers ? local_speakers.filter((speaker) => speaker !== e.target.name) : [];
      }
      setFieldTouched(`models_arr[${modelArrIndex}].local_speakers`, true);
      setFieldValue(`models_arr[${modelArrIndex}].local_speakers`, currentLocalSpeakers);
    },
    [setFieldValue, setFieldTouched, local_speakers, modelArrIndex]
  );

  const handleOsCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let currentOsVersions: string[] = os_versions;
      const isChecked = e.target.checked;
      if (isChecked) {
        currentOsVersions = os_versions ? [...os_versions, e.target.name] : [e.target.name];
      } else {
        currentOsVersions = os_versions ? os_versions.filter((os) => os !== e.target.name) : [];
      }
      setFieldTouched(`models_arr[${modelArrIndex}].os_versions`, true);
      setFieldValue(`models_arr[${modelArrIndex}].os_versions`, currentOsVersions);
    },
    [setFieldValue, setFieldTouched, modelArrIndex, os_versions]
  );

  const handleAudioHalOtherChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(`models_arr[${modelArrIndex}].audio_hal_other_text`, e.target.value);
    },
    [modelArrIndex]
  );

  const handleOsOtherChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(`models_arr[${modelArrIndex}].os_other_text`, e.target.value);
    },
    [modelArrIndex]
  );

  const handleDecodeOptionsCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let currentDecodeOptions: string[] = decode_options;
      const isChecked = e.target.checked;
      if (isChecked) {
        currentDecodeOptions = decode_options ? [...decode_options, e.target.name] : [e.target.name];
      } else {
        currentDecodeOptions = decode_options ? decode_options.filter((decodeOption) => decodeOption !== e.target.name) : [];
      }
      setFieldTouched(`models_arr[${modelArrIndex}].decode_options`, true);
      setFieldValue(`models_arr[${modelArrIndex}].decode_options`, currentDecodeOptions);
    },
    [setFieldValue, setFieldTouched, modelArrIndex, decode_options, errors]
  );

  return (
    <div>
      <div className={styles.header}>Customer Hardware</div>
      <div className={styles.section}>
        <div>
          <div className={styles.inputItem}>
            <label>Device Type</label>
            <Select
              selectProps={{
                name: "dev_type",
                value: dev_type,
                placeholder: "Select device type",
                onChange: handleSelectChange,
                onBlur: handleBlur,
              }}
              options={[
                { label: "TV", value: "tv" },
                { label: "Set top box", value: "set_top_box" },
                { label: "Projector", value: "projector" },
                { label: "Tablet", value: "tablet" },
                { label: "Phone", value: "phone" },
              ]}
            />
            {isValidationPassed("dev_type") && <span className={styles.error}>{getError("dev_type")}</span>}
          </div>
          <div className={styles.inputItem}>
            <label>Local Speakers</label>
            <div className={styles.checkboxContainer}>
              <Checkbox
                label="None"
                name={LOCAL_SPEAKERS_ENUM.NONE_LOCAL}
                checked={haveSelectedCheckboxValue("local_speakers", LOCAL_SPEAKERS_ENUM.NONE_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Front Left"
                name={LOCAL_SPEAKERS_ENUM.FRONT_LEFT_LOCAL}
                checked={haveSelectedCheckboxValue("local_speakers", LOCAL_SPEAKERS_ENUM.FRONT_LEFT_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Front Right"
                name={LOCAL_SPEAKERS_ENUM.FRONT_RIGHT_LOCAL}
                checked={haveSelectedCheckboxValue("local_speakers", LOCAL_SPEAKERS_ENUM.FRONT_RIGHT_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Center"
                name={LOCAL_SPEAKERS_ENUM.CENTER_LOCAL}
                checked={haveSelectedCheckboxValue("local_speakers", LOCAL_SPEAKERS_ENUM.CENTER_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Subwoofer"
                name="local_speaker_subwoofer"
                checked={haveSelectedCheckboxValue("local_speakers", "local_speaker_subwoofer")}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Height Front Right"
                name={LOCAL_SPEAKERS_ENUM.HEIGHT_FRONT_RIGHT_LOCAL}
                checked={haveSelectedCheckboxValue("local_speakers", LOCAL_SPEAKERS_ENUM.HEIGHT_FRONT_RIGHT_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Height Front Left"
                name={LOCAL_SPEAKERS_ENUM.HEIGHT_FRONT_LEFT_LOCAL}
                checked={haveSelectedCheckboxValue("local_speakers", LOCAL_SPEAKERS_ENUM.HEIGHT_FRONT_LEFT_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
            </div>
            {isValidationPassed("local_speakers") && <span className={styles.error}>{getError("local_speakers")}</span>}
          </div>
        </div>
        <div>
          <div className={styles.inputItem}>
            <label>Operating System</label>
            <Select
              selectProps={{
                name: "operating_system",
                value: operating_system,
                placeholder: "Select operating system",
                onChange: handleSelectChange,
                onBlur: handleBlur,
              }}
              options={[
                { label: "Android", value: "operating_system_android" },
                { label: "Linux", value: "operating_system_linux" },
                { label: "iOS", value: "operating_system_ios" },
                { label: "Fire TV", value: "operating_system_fire_tv" },
                { label: "Web OS", value: "operating_system_web_os" },
                { label: "Tiezen OS", value: "operating_system_tiezen_os" },
              ]}
            />
            {isValidationPassed("operating_system") && <span className={styles.error}>{getError("operating_system")}</span>}
          </div>
          <div className={styles.inputItem}>
            <label>OS Version</label>
            <div className={styles.checkboxContainer}>
              <Checkbox
                label="OS Android v11"
                name="os_android_11"
                checked={haveSelectedCheckboxValue("os_versions", "os_android_11")}
                onChange={handleOsCheckboxChange}
              />
              <Checkbox
                label="OS Android v12"
                name="os_android_12"
                checked={haveSelectedCheckboxValue("os_versions", "os_android_12")}
                onChange={handleOsCheckboxChange}
              />
              <Checkbox
                label="OS Android v13"
                name="os_android_13"
                checked={haveSelectedCheckboxValue("os_versions", "os_android_13")}
                onChange={handleOsCheckboxChange}
              />
              <Checkbox
                label="OS Android v14"
                name="os_android_14"
                checked={haveSelectedCheckboxValue("os_versions", "os_android_14")}
                onChange={handleOsCheckboxChange}
              />
              <Checkbox
                label="OS Other"
                name="os_other"
                checked={haveSelectedCheckboxValue("os_versions", "os_other")}
                onChange={handleOsCheckboxChange}
              />
              {haveSelectedCheckboxValue("os_versions", "os_other") && (
                <div className={styles.inputItem}>
                  <input
                    type="text"
                    placeholder="OS Other"
                    value={os_other_text}
                    name="os_other_text"
                    onBlur={handleBlur}
                    onChange={handleOsOtherChange}
                  />
                  {isValidationPassed("os_other_text") && <span className={styles.error}>{getError("os_other_text")}</span>}
                </div>
              )}
            </div>
            {isValidationPassed("os_versions") && <span className={styles.error}>{getError("os_versions")}</span>}
          </div>
        </div>
        <div>
          <div className={styles.inputItem}>
            <label>Audio HAL</label>
            <Select
              selectProps={{
                name: "audio_hal",
                value: audio_hal,
                placeholder: "Select audio HAL",
                onChange: handleSelectChange,
                onBlur: handleBlur,
              }}
              options={[
                { label: "Android", value: "audio_hal_android" },
                { label: "Mediatek Android HAL", value: "audio_hal_mediatek_android_hal" },
                { label: "Tiny ALSA", value: "audio_hal_mediatek_tiny_alsa" },
                { label: "ALSA", value: "audio_hal_alsa" },
                { label: "Other", value: "audio_hal_other" },
              ]}
            />
            {audio_hal === "audio_hal_other" && (
              <div className={styles.inputItem}>
                <input
                  type="text"
                  placeholder="Audio HAL"
                  value={audio_hal_other_text}
                  name="audio_hal_other_text"
                  onBlur={handleBlur}
                  onChange={handleAudioHalOtherChange}
                />
                {isValidationPassed("audio_hal_other_text") && <span className={styles.error}>{getError("audio_hal_other_text")}</span>}
              </div>
            )}
            {isValidationPassed("audio_hal") && <span className={styles.error}>{getError("audio_hal")}</span>}
          </div>
          <div className={styles.inputItem}>
            <label>Binder?</label>
            <div>
              <input
                id="binder_true"
                type="radio"
                name="binder"
                value="true"
                checked={binder === true}
                onChange={hanldeRadioChange}
                onBlur={handleBlur}
              />
              <label htmlFor="binder_true">Yes</label>
            </div>
            <div>
              <input
                id="binder_false"
                type="radio"
                name="binder"
                value="false"
                checked={binder === false}
                onChange={hanldeRadioChange}
                onBlur={handleBlur}
              />
              <label htmlFor="binder_true">No</label>
            </div>
            {isValidationPassed("binder") && <span className={styles.error}>{getError("binder")}</span>}
          </div>
        </div>
        <div className={styles.inputItem}>
          <label>Decode Options</label>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="2.0"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_0}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_0)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="2.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_1}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="2.1.2"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_2_1_2}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_2_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="3.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_3_1}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_3_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="3.1.2"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_3_1_2}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_3_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="4.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_4_1}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_4_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="5.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="5.1.2 S"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_DONGLE_HEIGHT_SURROUNDS_5_1_2}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_DONGLE_HEIGHT_SURROUNDS_5_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="5.1.2 F"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_DONGLE_HEIGHT_FRONTS_5_1_2}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_DONGLE_HEIGHT_FRONTS_5_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="5.1.4"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1_4}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1_4)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="7.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="7.1.2"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1_2}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="7.1.4"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1_4}
              checked={haveSelectedCheckboxValue("decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1_4)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
          </div>
          {isValidationPassed("decode_options") && <span className={styles.error}>{getError("decode_options")}</span>}
        </div>
      </div>
    </div>
  );
};

export default ManageProductAddOrEditProductExpressTxConfigurationsCustomerHardware;
