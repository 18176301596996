import { Formik, FormikHelpers } from "formik";
import { CustomerFormInitValues } from "../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";
import Form from "./Form";
import { validateCustomer } from "../../utils/validationSchemas";
import { http } from "../../api/utils/http";
import { toast } from "react-toastify";
import { addCustomer } from "../../api/config";
import { PostOrPutCustomerResponse } from "../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";

interface AddCustomerDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
}

// Initial values for add customer form
const initialValues: CustomerFormInitValues = {
  vendor_id: "",
  customer_name: "",
  manufacturer: false,
  customer_info: "",
  basecamp_email_address: "",
  customer_point_of_contact_id: null,
  fae_contact_id: null,
  sales_account_manager_id: null,
};

const AddCustomerDialog = (props: AddCustomerDialogProps) => {
  // Handler for form submit to save customer
  const handleAddCustomer = (values: CustomerFormInitValues, { setSubmitting }: FormikHelpers<CustomerFormInitValues>): void => {
    // Success callback for /add-customer API
    const onSuccess = ({ data: response }: AxiosResponse<PostOrPutCustomerResponse>) => {
      setSubmitting(false);
      if (response?.status) {
        // Display success toast and call parent callback to refresh the table
        toast.success(response?.message);
        props.callback();

        // Close the dialog
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /add-customer API
    http.makePostRequest<PostOrPutCustomerResponse, { message: string }>(
      addCustomer,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      values
    );
  };

  return (
    <>
      <div className={styles.dialogContainer}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddCustomer}
          onReset={props.onCancelClick}
          validationSchema={validateCustomer}>
          {(props) => <Form {...props} />}
        </Formik>
      </div>
    </>
  );
};

export default AddCustomerDialog;
