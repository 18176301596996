import { ChangeEvent, Dispatch, SetStateAction } from "react";
import Select from "../../../components/Select";
import styles from "../styles.module.scss";
import { WisaProductModel } from "../../../interfaces/be.interfaces";
import Checkbox from "../../../components/Checkbox";

export interface ModuleProgrammingFirmwareProgrammingProps {
  customerList: { label: string; value: string }[];
  deviceType: string;
  isSequenceRunning: boolean;
  modelList: {
    label: string;
    value: number;
    use_case_id: number;
  }[];
  onChangeCustomerProduct: (event: ChangeEvent<HTMLSelectElement>) => void;
  onChangeModel: (event: ChangeEvent<HTMLSelectElement>) => void;
  onChangeVendor: (event: ChangeEvent<HTMLSelectElement>) => void;
  onChangeWisaProduct: (event: ChangeEvent<HTMLSelectElement>) => void;
  productList: { label: string; value: number }[];
  product_id: number;
  customer_product_model_id: number;
  scannedMac: string;
  macAddresses: string[];
  vendor_id: string;
  wisaProducts: WisaProductModel[];
  isRemoteMacAvailable: boolean;
  setIsRemoteMacAvailable: Dispatch<SetStateAction<boolean>>;
}

const ModuleProgrammingFirmwareProgramming = ({
  customerList,
  deviceType,
  isSequenceRunning,
  modelList,
  customer_product_model_id,
  onChangeCustomerProduct,
  onChangeModel,
  onChangeVendor,
  onChangeWisaProduct,
  productList,
  product_id,
  macAddresses,
  vendor_id,
  wisaProducts,
  isRemoteMacAvailable,
  setIsRemoteMacAvailable,
  scannedMac,
}: ModuleProgrammingFirmwareProgrammingProps) => {
  return (
    <div className={`w-100 d-flex justify-content-between ${styles.deviceInfo} ${styles.firmwareProgrammingDeviceInfo}`}>
      <div className={styles.deviceConfiguration}>
        <div className={`d-flex ${styles.infoboxContainer} ${styles.infoboxContainerTransparent}`}>
          <span>Customer:</span>
          <Select
            selectProps={{
              name: "vendor_id",
              placeholder: !customerList?.length ? "Select Customer" : undefined,
              value: vendor_id,
              onChange: onChangeVendor,
              className: styles.customSelectBox,
              disabled: isSequenceRunning,
            }}
            options={customerList}
          />
        </div>
        <div className={`d-flex ${styles.infoboxContainer} ${styles.infoboxContainerTransparent}`}>
          <span>Product:</span>
          <Select
            selectProps={{
              name: "product_id",
              placeholder: !productList?.length ? "Select Product" : undefined,
              value: product_id,
              onChange: onChangeCustomerProduct,
              className: styles.customSelectBox,
              disabled: isSequenceRunning,
            }}
            options={productList}
          />
        </div>
        <div className={`d-flex ${styles.infoboxContainer} ${styles.infoboxContainerTransparent}`}>
          <span>Model:</span>
          <Select
            selectProps={{
              placeholder: !modelList?.length ? "Select Model" : undefined,
              value: customer_product_model_id,
              onChange: onChangeModel,
              className: styles.customSelectBox,
              disabled: isSequenceRunning,
            }}
            options={modelList}
          />
        </div>
        <div className={styles.remoteMacCheckbox}>
          <Checkbox
            label="Is remote device available?"
            checked={isRemoteMacAvailable}
            onChange={() => {
              setIsRemoteMacAvailable(!isRemoteMacAvailable);
            }}
            disabled={isSequenceRunning}
          />
        </div>
      </div>
      <div className={styles.firmwareProgrammingMacScanInputWrapper}>
        <div className={`d-flex ${styles.infoboxContainer}`}>
          <span>WiSA Product Type:</span>
          <Select
            selectProps={{
              name: "wisa_product",
              placeholder: "WiSA Product",
              value: deviceType,
              onChange: onChangeWisaProduct,
              className: styles.customSelectBox,
              disabled: isSequenceRunning,
            }}
            options={wisaProducts}
            optionLabel="type"
            optionValue="type"
          />
          {isRemoteMacAvailable ? (
            <div className={styles.macAddressContainer}>
              {new Array(2).fill(0).map((_, index) => (
                <label key={index}>
                  {macAddresses[index] ?? (index === 0 ? "Connected device MAC address" : "Remote device MAC address")}
                </label>
              ))}
            </div>
          ) : (
            <div className={styles.macAddressContainer}>
              <label>{scannedMac || "Connected device MAC address"}</label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModuleProgrammingFirmwareProgramming;
