import { AxiosResponse } from "axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { editCustomerModule, getCustomerModule } from "../../api/config";
import { http } from "../../api/utils/http";
import { useFetch } from "../../hooks/useFetch";
import { UpdateItemResponse } from "../../interfaces/be.interfaces";
import { CustomerModule } from "../../interfaces/fe.interfaces";
import { CUSTOMER_MODULES, CUSTOMER_MODULE_WITH_TITLE } from "../../utils/constants";
import Spinner from "../Spinner";
import styles from "./styles.module.scss";
import Checkbox from "../Checkbox";

interface EditCustomerModuleDialogProps {
  vendor_id: string;
  onCancelClick: () => void | Promise<void>;
}

/**
 * This component will use for update the customer's module access
 * This will show the dialog component and admin should able to access that
 * @returns
 */
const EditCustomerModuleDialog = (props: EditCustomerModuleDialogProps) => {
  const [selectedModules, setSelectedModules] = useState<CUSTOMER_MODULES[]>([]);

  // Fetch customer modules
  const {
    data,
    loading,
    error: customerError,
  } = useFetch<AxiosResponse<CustomerModule>>({
    url: `${getCustomerModule}/${props.vendor_id}`,
    runOnMount: true,
    onSuccess: (res) => {
      if (res.data.data.modules) setSelectedModules(res.data.data.modules);
    },
  });

  const onSubmit = useCallback((): void => {
    const payload: CustomerModule = {
      vendor_id: props.vendor_id,
      modules: selectedModules,
    };

    // Success callback for /update-customer-module API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse<CustomerModule>>) => {
      if (response?.status) {
        toast.success(response?.message);

        // Close the dialog and call parent callback
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /update-customer-module API
    http.makePutRequest<UpdateItemResponse<CustomerModule>, { message: string }>(
      editCustomerModule,
      onSuccess,
      (error) => {
        toast.error(error?.response?.data?.message);
      },
      payload
    );
  }, [props.vendor_id, selectedModules]);

  // This function will use for update the module
  const changeModule = useCallback(
    (module: CUSTOMER_MODULES) => {
      let modules = [...selectedModules];

      // If module is already selected then we'll remove the module
      // Else we'll push that module
      if (modules.includes(module)) modules = modules.filter((item) => item !== module);
      else modules.push(module);

      setSelectedModules(modules);
    },
    [selectedModules, setSelectedModules]
  );

  return (
    <div className={styles.dialogContainer}>
      {customerError ? <div className="errorAlert">{customerError}</div> : null}
      <div className={styles.dialog}>
        {loading ? (
          <Spinner />
        ) : data?.data ? (
          <>
            {CUSTOMER_MODULE_WITH_TITLE.map((item) => (
              <div key={item.title} className={styles.moduleCheckbox}>
                <Checkbox
                  id={item.title}
                  type="checkbox"
                  onChange={() => changeModule(item.module)}
                  checked={selectedModules.includes(item.module)}
                  label={item.title}
                />
              </div>
            ))}
            <div className={`${styles.dialogBtnContainer} w-100 d-flex justify-content-around`}>
              <button className={styles.dialogBtn} type="button" onClick={() => onSubmit()}>
                Update
              </button>
              <button type="button" className={styles.dialogBtn} onClick={props.onCancelClick}>
                Cancel
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default EditCustomerModuleDialog;
