import { FormikProps } from "formik";
import Spinner from "../Spinner";
import styles from "./styles.module.scss";
import Select from "../Select";
import { getAllUsers, getCustomerInfo } from "../../api/config";
import { useMemo, useState } from "react";
import { CustomerFormInitValues } from "../../interfaces/fe.interfaces";
import { CustomerInfoResponse, GetUsersResponse } from "../../interfaces/be.interfaces";
import { useFetch } from "../../hooks/useFetch";
import { handleAlphaNumInputChange } from "../../utils/helpers";
import { ROLE_ID } from "../../utils/constants";

interface CustomerProps {
  label: string;
  value: string;
}

/**
 * Common form component for AddCustomer and EditCustomer
 * Takes in all the props from Formik component
 */
const Form = ({
  values,
  touched,
  setFieldTouched,
  errors,
  handleSubmit,
  handleReset,
  setFieldValue,
  isSubmitting,
  enableReinitialize,
}: FormikProps<CustomerFormInitValues>) => {
  const [customers, setCustomers] = useState<CustomerProps[]>([]);

  // Get list of customer info options to display in dropdown selection
  const { loading: customerInfoLoading, error: customerError } = useFetch<CustomerInfoResponse>({
    url: getCustomerInfo,
    runOnMount: true,
    onSuccess: (response) => {
      if (response?.status) {
        const dropdownItemArr: CustomerProps[] = [];

        // Converting the response to the format required by the dropdown component
        response?.data?.data?.customer_info_arr.map((value: any) => {
          dropdownItemArr.push({ label: value, value: value });
        });

        setCustomers(dropdownItemArr);
      }
    },
  });

  // Get list of all users available in the system
  // Hook to get the list of the user
  const { data: user } = useFetch<GetUsersResponse>({
    url: getAllUsers,
    runOnMount: true,
  });

  const wisaSalesUsers = useMemo(() => {
    return [
      {
        label: "Select Sales Manager",
        value: "",
      },
      ...(user?.data.list
        .filter((value) => value.role_id === ROLE_ID.WISA_SALES)
        .map((value) => ({
          label: value.name,
          value: value.user_id,
        })) || []),
    ];
  }, [user]);

  const wisaFaeUsers = useMemo(() => {
    return [
      {
        label: "Select FAE Contact",
        value: "",
      },
      ...(user?.data.list
        .filter((value) => value.role_id === ROLE_ID.WISA_FAE)
        .map((value) => ({
          label: value.name,
          value: value.user_id,
        })) || []),
    ];
  }, [user]);

  const pointOfContactUsers = useMemo(() => {
    return [
      {
        label: "Select Customer POC",
        value: "",
      },
      ...(user?.data.list
        .filter((value) => value.vendor_id === values.vendor_id && value.customer_point_of_contact === true)
        .map((value) => ({
          label: value.name,
          value: value.user_id,
        })) || []),
    ];
  }, [user, values.vendor_id]);

  return (
    <form onSubmit={handleSubmit}>
      {customerError ? <div className="errorAlert">{customerError}</div> : null}
      <div className={styles.dialog}>
        {isSubmitting || customerInfoLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center `}>
                <input
                  name="vendor_id"
                  type="text"
                  placeholder={"Enter Vendor ID"}
                  value={values?.vendor_id}
                  onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
                  onBlur={() => setFieldTouched("vendor_id", true)}
                />
                {errors?.vendor_id && touched.vendor_id ? <p className={styles.error}>{errors?.vendor_id}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Select
                  selectProps={{
                    name: "customer_info",
                    value: values.customer_info || "",
                    placeholder: "Select Customer Info",
                    onChange: (event) => setFieldValue("customer_info", event.target.value),
                  }}
                  options={customers}
                />
                {errors?.customer_info && touched.customer_info ? <p className={styles.error}>{errors?.customer_info}</p> : null}
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="customer_name"
                  type="text"
                  placeholder={"Enter Vendor Name"}
                  value={values.customer_name}
                  onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
                  onBlur={() => setFieldTouched("customer_name", true)}
                />
                {errors?.customer_name && touched.customer_name ? <p className={styles.error}>{errors?.customer_name}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Select
                  selectProps={{
                    name: "customer_point_of_contact_id",
                    value: values.customer_point_of_contact_id || "",
                    onChange: (event) => setFieldValue("customer_point_of_contact_id", event.target.value ? +event.target.value : null),
                  }}
                  options={pointOfContactUsers}
                />
                {errors?.customer_point_of_contact_id && touched.customer_point_of_contact_id ? (
                  <p className={styles.error}>{errors?.customer_point_of_contact_id}</p>
                ) : null}
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="basecamp_email_address"
                  type="email"
                  placeholder={"Enter basecamp email"}
                  value={values.basecamp_email_address || ""}
                  onChange={(e) => setFieldValue("basecamp_email_address", e.target.value)}
                  onBlur={() => setFieldTouched("basecamp_email_address", true)}
                />
                {errors?.basecamp_email_address && touched.basecamp_email_address ? (
                  <p className={styles.error}>{errors?.basecamp_email_address}</p>
                ) : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Select
                  selectProps={{
                    name: "sales_account_manager_id",
                    value: values.sales_account_manager_id || "",
                    onChange: (event) => setFieldValue("sales_account_manager_id", event.target.value ? +event.target.value : null),
                  }}
                  options={wisaSalesUsers}
                />
                {errors?.sales_account_manager_id && touched.sales_account_manager_id ? (
                  <p className={styles.error}>{errors?.sales_account_manager_id}</p>
                ) : null}
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <div>
                  <span>Manufacturer</span>
                </div>
                <div className={"d-flex justify-content-between align-item-center"}>
                  <div className="d-flex">
                    <input
                      type="radio"
                      name="manufacturer"
                      id="yes"
                      checked={values.manufacturer === true}
                      onChange={() => setFieldValue("manufacturer", true)}
                    />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className="d-flex" style={{ marginLeft: 10 }}>
                    <input
                      type="radio"
                      name="manufacturer"
                      id="no"
                      checked={values.manufacturer === false}
                      onChange={() => setFieldValue("manufacturer", false)}
                    />
                    <label htmlFor="no">No</label>
                  </div>
                </div>
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Select
                  selectProps={{
                    name: "fae_contact_id",
                    value: values.fae_contact_id || "",
                    onChange: (event) => setFieldValue("fae_contact_id", event.target.value ? +event.target.value : null),
                  }}
                  options={wisaFaeUsers}
                />
                {errors?.fae_contact_id && touched.fae_contact_id ? <p className={styles.error}>{errors?.fae_contact_id}</p> : null}
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <button className={styles.dialogBtn} type="submit" disabled={isSubmitting}>
                {enableReinitialize ? "Update" : "Add"}
              </button>
              <button type="button" className={styles.dialogBtn} onClick={handleReset}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Form;
