import { useState, useCallback } from "react";
import styles from "./styles.module.scss";
import { FormikProps } from "formik";
import { ExpressTxConfigurationsResponseModel, ExpressDecodeOptionsModel } from "../../../interfaces/be.interfaces";
import { ProductFormInitValues, ModelPayload } from "../../../interfaces/fe.interfaces";
// eslint-disable-next-line max-len
import ManageProductAddOrEditProductExpressTxConfigurationsActualProduct from "./ManageProductAddOrEditProductExpressTxConfigurationsActualProduct";
// eslint-disable-next-line max-len
import ManageProductAddOrEditProductExpressTxConfigurationsCustomerHardware from "./ManageProductAddOrEditProductExpressTxConfigurationsCustomerHardware";
// eslint-disable-next-line max-len
import ManageProductAddOrEditProductExpressTxConfigurationsYourProduct from "./ManageProductAddOrEditProductExpressTxConfigurationsYourProduct";
// import { validateManageProductExpressTxConfigurations } from "../../../utils/validationSchemas";
import { http } from "../../../api/utils/http";
import { expressTxConfigurationsRoute } from "../../../api/config";
import { AxiosError, AxiosResponse } from "axios";

export const expressTxConfigurationsFormModelInIt = {
  dev_type: "",
  local_speakers: [],
  operating_system: "",
  os_versions: [],
  os_other_text: "",
  decode_options: [],
  audio_hal: "",
  audio_hal_other_text: "",
  binder: false,
  rx_configurations: [],
  sync_requirements: "",
  tsf_available: false,
};

export interface ManageProductAddOrEditProductExpressTxConfigurationsProps extends FormikProps<ProductFormInitValues> {
  modelArrIndex: number;
}

const ManageProductAddOrEditProductExpressTxConfigurations = (props: ManageProductAddOrEditProductExpressTxConfigurationsProps) => {
  const [actualProduct, setActualProduct] = useState<ExpressDecodeOptionsModel | null>(null);

  const [error, setError] = useState<string | null>(null);

  const onSuccess = useCallback(
    ({ data: response }: AxiosResponse<ExpressTxConfigurationsResponseModel>) => {
      const { actual_product } = response.data;
      setActualProduct(actual_product);
    },
    [setActualProduct, actualProduct]
  );

  const onError = useCallback(
    (
      error:
        | AxiosError<
            {
              message: string;
            },
            any
          >
        | undefined
    ) => {
      setError(error?.response?.data?.message ?? null);
    },
    [setError]
  );

  const getExpressTxConfiguration = useCallback(
    (expressTxOption: string) => props.values.models_arr[props.modelArrIndex][expressTxOption as keyof ModelPayload],
    [props.modelArrIndex, props.values.models_arr]
  );

  const handleClick = useCallback(() => {
    const payload = {
      dev_type: getExpressTxConfiguration("dev_type"),
      local_speakers: getExpressTxConfiguration("local_speakers"),
      operating_system: getExpressTxConfiguration("operating_system"),
      os_versions: getExpressTxConfiguration("os_versions"),
      os_other_text: getExpressTxConfiguration("os_other_text"),
      decode_options: getExpressTxConfiguration("decode_options"),
      audio_hal: getExpressTxConfiguration("audio_hal"),
      audio_hal_other_text: getExpressTxConfiguration("audio_hal_other_text"),
      binder: getExpressTxConfiguration("binder"),
      rx_configurations: getExpressTxConfiguration("rx_configurations"),
      sync_requirements: getExpressTxConfiguration("sync_requirements"),
      tsf_available: getExpressTxConfiguration("tsf_available"),
    };

    http.makePostRequest<ExpressTxConfigurationsResponseModel, { message: string }>(
      expressTxConfigurationsRoute,
      onSuccess,
      onError,
      payload
    );
  }, [onSuccess, onError, expressTxConfigurationsRoute, props.values]);

  return (
    <fieldset className={styles.expressTxOptionsFieldset}>
      {error && <div className="errorAlert">{error}</div>}
      <div className="d-flex align-item-baseline justify-content-between">
        <h2 className={styles.expressTxOptionsHeader}>Express TX Configurations</h2>
        <button type="button" onClick={handleClick} disabled={false}>
          Analyze Configuration
        </button>
      </div>
      <div className={styles.manageProductExpressTxConfigurationsContainer}>
        <ManageProductAddOrEditProductExpressTxConfigurationsCustomerHardware {...props} />
        <ManageProductAddOrEditProductExpressTxConfigurationsYourProduct {...props} />
        {actualProduct && <ManageProductAddOrEditProductExpressTxConfigurationsActualProduct actualProduct={actualProduct} />}
      </div>
    </fieldset>
  );
};

export default ManageProductAddOrEditProductExpressTxConfigurations;
