import { FormikProps, getIn } from "formik";
import { ExpressTxConfigurationsModel, ProductFormInitValues } from "../../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";
import { ChangeEvent, useCallback } from "react";
import Checkbox from "../../../components/Checkbox";

interface ManageProductAddOrEditProductExpressTxConfigurationsYourProductProps extends FormikProps<ProductFormInitValues> {
  modelArrIndex: number;
}

const ManageProductAddOrEditProductExpressTxConfigurationsYourProduct = ({
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  handleBlur,
  modelArrIndex,
}: ManageProductAddOrEditProductExpressTxConfigurationsYourProductProps) => {
  const { rx_configurations, sync_requirements, tsf_available } = values.models_arr[modelArrIndex];

  const isValidationPassed = useCallback(
    (fieldName: string) => {
      return getIn(touched, `models_arr[${modelArrIndex}].${fieldName}`) && getIn(errors, `models_arr[${modelArrIndex}].${fieldName}`);
    },
    [errors, touched, modelArrIndex, values]
  );

  const getError = useCallback(
    (fieldName: string) => getIn(errors, `models_arr[${modelArrIndex}].${fieldName}`),
    [errors, touched, modelArrIndex]
  );

  const haveSelectedCheckboxValue = useCallback(
    (checkboxName: ExpressTxConfigurationsModel, checkboxValue: string) => {
      return values.models_arr[modelArrIndex][checkboxName]?.includes(checkboxValue) ?? false;
    },
    [values.models_arr, modelArrIndex]
  );

  const handleRxConfigurationsCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let currentRxConfigurations: string[] = rx_configurations;
      const isChecked = e.target.checked;
      if (isChecked) {
        currentRxConfigurations = rx_configurations ? [...rx_configurations, e.target.name] : [e.target.name];
      } else {
        currentRxConfigurations = rx_configurations ? rx_configurations.filter((rx) => rx !== e.target.name) : [];
      }
      setFieldTouched(`models_arr[${modelArrIndex}].rx_configurations`, true);
      setFieldValue(`models_arr[${modelArrIndex}].rx_configurations`, currentRxConfigurations);
    },
    [setFieldValue, modelArrIndex, rx_configurations]
  );

  const hanldeRadioChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let val: string | boolean = e.target.value;
      if (val === "true") {
        val = true;
      } else if (val === "false") {
        val = false;
      }
      setFieldValue(`models_arr[${modelArrIndex}].${e.target.name}`, val);
    },
    [setFieldValue]
  );

  return (
    <div>
      <div className={styles.header}>Your Product</div>
      <div className={styles.section}>
        <div className={styles.inputItem}>
          <label>RX Configuration Options</label>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="2.0"
              name="rx_configuration_options_2.0"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_2.0")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="2.1"
              name="rx_configuration_options_2.1"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_2.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="2.1.2"
              name="rx_configuration_options_2.1.2"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_2.1.2")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="3.1"
              name="rx_configuration_options_3.1"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_3.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="3.1.2"
              name="rx_configuration_options_3.1.2"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_3.1.2")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="4.1"
              name="rx_configuration_options_4.1"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_4.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="5.1"
              name="rx_configuration_options_5.1"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_5.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="5.1.2 S"
              name="rx_configuration_options_5.1.2_dongle_height_surrounds"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_5.1.2_dongle_height_surrounds")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="5.1.2 F"
              name="rx_configuration_options_5.1.2_dongle_height_fronts"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_5.1.2_dongle_height_fronts")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="5.1.4"
              name="rx_configuration_options_5.1.4"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_5.1.4")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="7.1"
              name="rx_configuration_options_7.1"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_7.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="7.1.2"
              name="rx_configuration_options_7.1.2"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_7.1.2")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="7.1.4"
              name="rx_configuration_options_7.1.4"
              checked={haveSelectedCheckboxValue("rx_configurations", "rx_configuration_options_7.1.4")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
          </div>
          {isValidationPassed("rx_configurations") && <span className={styles.error}>{getError("rx_configurations")}</span>}
        </div>
        <div className={styles.inputItem}>
          <label>Sync Requirements</label>
          <div>
            <input
              id="sync_requirements_greater_than_300_micro_seconds"
              type="radio"
              name="sync_requirements"
              value="greater_than_300_micro_seconds"
              checked={sync_requirements === "greater_than_300_micro_seconds"}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="sync_requirements_greater_than_300_micro_seconds">{"\u003E"} 150 micro seconds</label>
          </div>
          <div>
            <input
              id="sync_requirements_less_than_150_micro_seconds"
              type="radio"
              name="sync_requirements"
              value="less_than_150_micro_seconds"
              checked={sync_requirements === "less_than_150_micro_seconds"}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="sync_requirements_less_than_150_micro_seconds">{"\u003C"} 150 micro seconds</label>
          </div>
          <div>
            <input
              id="sync_requirements_less_than_+/-_21_micro_seconds"
              type="radio"
              name="sync_requirements"
              value="less_than_+/-_21_micro_seconds"
              checked={sync_requirements === "less_than_+/-_21_micro_seconds"}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="sync_requirements_less_than_+/-_21_micro_seconds">{"\u003C"} +/- 21 micro seconds</label>
          </div>
          {isValidationPassed("sync_requirements") && <span className={styles.error}>{getError("sync_requirements")}</span>}
        </div>
        <div className={styles.inputItem}>
          <label>TSF Available?</label>
          <div>
            <input
              id="tsf_available_true"
              type="radio"
              name="tsf_available"
              value="true"
              checked={tsf_available === true}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="tsf_available_true">Yes</label>
          </div>
          <div>
            <input
              id="tsf_available_false"
              type="radio"
              name="tsf_available"
              value="false"
              checked={tsf_available === false}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="tsf_available_true">No</label>
          </div>
          {isValidationPassed("tsf_available") && <span className={styles.error}>{getError("tsf_available")}</span>}
        </div>
      </div>
    </div>
  );
};

export default ManageProductAddOrEditProductExpressTxConfigurationsYourProduct;
